// 69359
.desktop-navigation{
    .menu-group{
        & > .navbar-nav {
            max-width: 100%;  
            & > .nav-item{
                & > .nav-link {
                    font-family: $font-nav;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 26px;
                    outline: none;
                    background-color: transparent;
                    box-sizing: border-box;
                    position: relative;
            
                    @include media-breakpoint-up(md) {
                        color: $white;
                        padding: 12px 0;
                    }
                    &:before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        bottom: 0;
                        height: 4px;
                        background-color: transparent;
                        left: 0;
                    }
        
                    &:hover, &:focus, &.active {
                        background-color: transparent;
                        color: $white;     
                        
                        &:before{                   
                            background-color: $yellow;
                        }
                    }
                }
                &:hover, &:focus, &.active {
                    & > .nav-link {
                        background-color: transparent;
                        color: $white;     
                        
                        &:before{                   
                            background-color: $yellow;
                        }
                    }                
                }
                &:first-child{
                    .nav-link{
                        @include media-breakpoint-up(md) {
                            margin-left: 0;
                        }
                    }
                }
            }        
            
        }
    }
}
.navbar.bg-inverse{
    .mega-menu{
        position: absolute;
        z-index: 1;
        background: $primary-dark;
        color: $white;
        height:0;
        overflow: hidden;
        max-width: 98vw;

        &:focus, &:active{
            height:auto !important;
        }

        &__wrapper{
            padding: 42px;
            position: relative;
            overflow: hidden;

            &:after{
                content: '';
                height: 33.5%;
                width: 200%;
                background: $primary-darkest;
                position: absolute;
                bottom: -12%;
                left: -50%;
                transform: rotate(-8deg);
                z-index: -1;
                opacity: 0.2;
            }

            &:before{
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: url(../../images/menu-watermark.svg) no-repeat;
                background-size: contain; 
                background-position: right bottom;  
                z-index: -1;             
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
            }

            .category-nav{
                ul ul{
                    display: none;
                }
            }
            
            .navbar-nav{
                display: block;

                .nav-item{
                    display: block;
                    margin-bottom: 20px;

                    .nav-link{
                        padding: 0;
                        font-size: 12px;
                        line-height: 16px;
                        font-family: $primary-font;
                        position: relative;
                        background: transparent !important;
                        color: $white !important; 

                        &:hover, &:focus{
                            background-color: transparent;
                            color: $white;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .featured-dropdown{
                padding: 20px 20px 20px 20px;
                background-color: $primary-darkest;                               
                // 146186
                h5, .featured-course-heading{
                    font-family: $font-nav;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    color: $white;
                    @include media-breakpoint-up(md){
                        .mobile-arrow-down{
                            display: none;
                        }
                    }
                }
                .navbar-nav{
                    .nav-item{
                        margin-bottom: 20px;
                        
                        &:last-child{
                            margin-bottom: 0;
                        }
    
                        .nav-link{
                            font-size: 12px;
                            line-height: 16px;                            
                        }
                    }
                } 
            } 
            
            
            .category-nav{
                padding: 20px 25px;
                .navbar-nav{
                    .nav-item{ 
                        &:last-child{
                            margin-bottom: 0;
                        }                       
                        .nav-link{
                            text-transform: uppercase; 
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            padding-left: 12px; 
                            
                            &:before{
                                position: absolute;
                                left:0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                width: 3px;
                                height: 100%;
                                background: transparent;
                                content: '';
                                display: block;
                            }
                            
                            &.active, &:focus, &:hover{
                                &:before{
                                    background: $yellow;
                                }                                
                            }
                        }
                    }
                }
            }

            .category-nav__sub{
                padding: 20px 0px 20px 32px;
                border-left: 2px solid rgba($midgray, 0.5);

                .navbar-nav{
                    .nav-item{
                        &:last-child{
                            margin-bottom: 0;
                        }
                        // 26957 Start - changed transform from capitalized to none
                        .nav-link{
                            text-transform: none;
                        }
                        // 26957 End 
                    }
                } 
            }
        }
    }
}
.navbar.bg-inverse{
    .navbar-nav {          
        .nav-item{

            &:hover, &:active, &:focus{
                .mega-menu{
                    height: auto !important;
                }
            }
            .nav-link {
                @include media-breakpoint-up(md) {                    
                    padding: 12px 0;
                    margin-left: 24px;
                }  

                &:hover, &:focus{
                    & + .mega-menu{
                        height: auto !important;
                    }
                }              
            }    
            &:first-child{
                .nav-link{
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                    }
                }
            }
 
        }    
    }
}

.desktop-navigation{
    @include media-breakpoint-down(sm){
        display: none;
    }
}
.mobile-navigation{
    @include media-breakpoint-up(md){
        display: none;

        .mobile-arrow-down{
            display: none;
        }
    }
    @include media-breakpoint-down(sm){
        display: block !important;
    }
}

.menu-toggleable-left.navbar-toggleable-sm{
    @include media-breakpoint-down(sm){
        left: -100%;
        display: block !important;
        width: 360px;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
        transition: all 0.4s ease 0s;
        max-width: 100%;

        .text-underline{
            text-decoration: underline !important;
        }
        /*63900*/
        &.active{
            left: 0;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .menu-group{
            position: relative;
        }

        .container{
            padding: 0;
        }

        .mobile-navigation{
            overflow-x: hidden;
            height: 100%;
            padding-top: 84px;
            padding-bottom: 70px;

            li.featured-courses{

                .hide-mobile{
                    display: none;
                }
            }

            .navbar-nav{
                display:block;
            }
            .requestinfo-static{
                position: fixed;
                padding: 15px 20px;
                background-color: $white;
                left: 0;
                bottom: 0;
                width: 360px;
                max-width: 100%;
            }
            .mobile-menu__close{
                width: 20px;
                height: 20px;
                position: fixed;
                left: 327px;
                top: 36px;
                background: url(../../images/icon-close.svg) no-repeat;
                background-position: center;
                background-size: 12px;
                text-indent: -999999999px;
                overflow: hidden;
                z-index: 2;

                @media (max-width:359px){
                    left: auto;
                    right: 20px;
                }
            }

            .user-mobile{
                background: $primary;
                display: none;
                padding: 26px 20px;
                transition: all 0.4s ease 0s;
                transform: translateX(-105%);
                position: fixed;
                top:0;
                left: 0;
                width: 360px;
                max-width: 100%;
                z-index: 1;
                &.active{
                    display: block;
                    transform: translateX(0%);
                }
                a{
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: normal;
                    color: $white;
                    padding: 0;
                    text-transform: uppercase;
                    margin-right: 40px;

                    .account-user__icon{
                        margin-right: 10px;
                    }
                }                
            }
            .menu-title{
                display: none;
                background: $primary;
                padding: 25px 24px;
                transition: all 0.4s ease 0s;
                transform: translateX(105%);
                &.active{
                    display: block;
                    transform: translateX(0%);
                }
                a{                    
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: normal;
                    color: $white;
                    padding: 0 0 0 26px;
                    display: block;
                    
                    &.menu-back{
                        background: url(../../images/icon-back.svg) no-repeat;
                        background-position: left center;
                    }
                }
            }

            .nav-item{
                .nav-link{
                    font-family: $primary-font-extra-bold;
                    font-size: 12px;
                    line-height: 26px; 
                    color: $primary;
                    padding: 15px 20px;
                    
                    &.font14{
                        font-size: 14px;
                        font-family: $primary-font-bold;
                        font-weight: bold;
                    }
                }
                
                .nav-item .nav-link{
                    font-size: 12px;
                    font-family: $primary-font-semibold;
                    padding: 10px 20px;
                }
                // 149642
                &.featured-courses{
                    h5, .featured-course-heading {
                        font-family: $primary-font-extra-bold;
                        font-size: 12px;
                        line-height: 26px; 
                        color: $primary;
                        padding: 15px 20px;
                        margin:0;
                        text-transform: uppercase;
                    }
                }
            }

            .h-line{
                position: relative;
                height: 1px;
                margin: 15px 0;
                width: 100%;

                &:after{
                    position: absolute;
                    left: 20px;
                    width: calc(100% - 40px);
                    top: 0;
                    border: 1px solid rgba($primary-dark,0.2);
                    content: '';
                }
            }

            .mobile-mainnav{
                padding-top: 15px;
                transition: all 0.4s ease 0s;
                transform: translateX(0);
                &.active{
                    transform: translateX(-105%);
                }
                h5{
                    position: relative;
                }
                .nav-item{
                    border: 0 none !important;
                    position: relative;
                    .nav-link{
                        &.has-submenu{
                            padding-right: 34px;
                            position: relative;
                            .mobile-arrow__right{
                                display: block;
                                width:10px;
                                height:10px;
                                background: url(../../images/arrow-down-blue.svg) no-repeat;                                
                                background-size: contain;
                                position: absolute;
                                right: 24px;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                transform: rotate(270deg);
                                transform-origin: center;
                                border: 0 none;
                                -webkit-appearance: none;
                            }
                        }
                    }
                } 
                .has-subcategory{
                    & > .nav.navbar-nav{
                        display: none;
                    }
                }
                
                .mobile-arrow-down{
                    display: block;
                    width: 48px;
                    height: 100%;
                    background: url(../../images/arrow-down-blue.svg) no-repeat;
                    background-size: 10px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-position: center;
                    z-index: 1;
                    cursor: pointer;
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 0 none;
                    max-height: 56px;
                    transition: all 0.3s ease 0s;
                    transform: rotate(0deg);
                    transform-origin: center;
                    text-indent: -99999999px;
                    overflow: hidden;
                    
                    &.active{
                        transform: rotate(180deg);
                    }
                }               
            }
            .mobile-category-nav{               
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                transition: all 0.4s ease 0s;
                transform: translateX(105%);
                display: none;
                &.active{
                    display: block;
                    transform: translateX(0%);
                }

                .main-category-list{

                    .nav-item{
                        .nav-link{
                            padding-top: 16px;
                            padding-bottom: 16px;
                            border-bottom: 1px solid $midgray;
                        }
                    }

                    & > .nav-item{
                        & > .nav-link{
                            text-transform: uppercase;
                            padding-top: 16px;
                            padding-bottom: 16px;
                            border-bottom: 1px solid $midgray;
                        }
                    }
                    .nav.navbar-nav{                        
                        display: none;
                        background-color: $light-gray;                        
                    }

                    .has-subcategory{
                        position: relative;
                        & > .nav-link{
                            padding-right: 50px
                        }                        
                    }                    
                }
                
            } 
            .featured-dropdown-mobile{

                h5{
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: normal;
                    color: $primary;
                    padding: 16px 50px 16px 24px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    position: relative;
                    border-bottom: 1px solid $midgray;

                    .mobile-arrow-down{
                        display: block;
                        width: 48px;
                        height: 100%;
                        max-height: 48px;
                        background: url(../../images/arrow-down-blue.svg) no-repeat;
                        background-size: 10px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-position: center;
                        z-index: 1;
                        cursor: pointer;
                        -webkit-appearance: none;
                        background-color: transparent;
                        border: 0 none;
                        transition: all 0.3s ease 0s;
                        transform: rotate(0deg);
                        transform-origin: center;
                        text-indent: -99999999px;
                        overflow: hidden;
                        
                        &.active{
                            transform: rotate(180deg);
                        }
                    }
                }
                // 69356
                .nav.navbar-nav{                        
                    display: none; 
                    background-color: $light-gray; 
                    .nav-item{
                        .nav-link{
                            padding-top: 16px;
                            padding-bottom: 16px;
                            border-bottom: 1px solid $midgray;

                            br{
                                display: none;
                            }
                        }
                    }                      
                }
            }           
        }
        /*63900*/
    }
}

.navbar-header .navbar-toggler{
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 22px;    
}

.mobile-logout{
    display: none;
}
.skip-to-cat, .skip-to-main-nav{
    background: #ffffff;
}

.mega-menu{
    .skip.mainnav{
		&+.category-nav{
			padding-left: 0;
		}
	}
}

.rfi-modal {
	.modal-dialog {
		.modal-content {
			border-radius: 0px;
		   .modal-header {
				.modal-title {
					font-family: $primary-font-extra-bold; 
					font-size: 18px;
					color: $secondary-black;
				}
				
				.close {
					font-family: $primary-font-light;
					color: $primary;
					opacity: 1;
				}
			}
	
			.modal-body {
				height: 85vh;
		    	overflow-y: auto;
			}
		}
		
		@include media-breakpoint-down(md) {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                max-width: 100%;

             .modal-content {
                height: auto;
                min-height: 100%;
                border-radius: 0;
                border: 0px;
            }
		}
	}
}


//Mobile RFI Modal Start
.rfi-modal{
    .request-for-info{
        &.c-module--bg-lagoon{
            background-color: $white;
        } 
        &.c-module--fullbleed{
            width: 100%;
            margin-left: auto;
        }
        .request-for-info__container{
            padding: 0;
        }
        .request-form__intro-wrapper{
            color: $white;
        }
        .c-module__reqform{
            margin: 0;
            border: 0 none;
            box-shadow: none;
        }

        .request-form__intro, .request-form__fields{
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    
}