$ns: homepage;

.#{$ns} {
    img {
        max-width: 100%;
    }

    .container {
        max-width: 1200px;
    }

    .accreditation {
        margin: 30px 0;
        @include media-breakpoint-up(md) {
            margin: 50px 0;
        }

        img {
            width: 75px;
            height: 75px;

            @include media-breakpoint-up(md) {
                width: 130px;
                height: 130px;
            }
        }
    }

    .request-for-info {
        background: $white;
        color: $white;
    }
}
