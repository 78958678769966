$ns : c-search-header;
$c-search-header__text--font: $primary-font-light !default;
$c-search-header__text--font-color: $bright-gray !default;
$c-search-header__text--border: 1px solid $nobel !default;


.#{$ns} {

    &__text {
        background-color: $white-smoke;
        border: none;
        border-bottom: $c-search-header__text--border;
        border-radius: 0;
        color: $c-search-header__text--font-color;
        font-family: $c-search-header__text--font;
        padding: 2px 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            background-color: $white;
            width: 200px;
        }

        &:focus {
            background-color: transparent;
            border-color: $nobel;
            box-shadow: none;
        }
    }

    &__toggle {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            margin-left: 30px;
        }
    }

    &__switch {
        background: transparent;
        height: 24px;
        width: 24px;
        border: none;
        outline: none !important;
        padding: 0;
        position: relative;

        &.active {
            background: none;

            &:before,
            &:after {
                content: "";
                background-color: $eastern-blue;
                border-radius: 5px;
                display: block;
                height: 2px;
                overflow: hidden;
                position: absolute;
                width: 100%;
            }

            &:before {
                transform: rotateZ(45deg);
            }

            &:after {
                transform: rotateZ(-45deg);
            }
        }
    }

    &__submit {
        background: none;
        border: none;
        outline: none;
        position: absolute;
        right: 10px;
        top: 5px;

        @include media-breakpoint-up(lg) {
            right: -5px;
            top: -5px;
        }

    }
}
