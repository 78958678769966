.nav-sticky{
    .st-global-header__nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        // 69927
        z-index: 104;
        // 58339
        background: $white;
    }

    #maincontent{
        margin-top: 140px;

        @media(max-width: 768px){
            margin-top: 64px;
        }
    }
}

// 61227
.st-global-header{
    .modal-title{
        font-family: $primary-font;
        font-size: 20px;
        font-weight: 500;
    }
    .modal-content{
        font-family: $primary-font;
        font-size: 16px;
    }
}
// 61227

.minicart {
    margin: 0;

    @include media-breakpoint-up(lg) {
        margin: 0 0 0 30px;
    }
    // 69356
    .minicart-quantity {
        background: transparent;
        color: $primary;
        font-family: $font-nav;
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
        position: relative;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
        vertical-align: middle;
        margin-left:10px;
    }
}

.navbar-header {
        margin: 23px 0;
        height: auto;

        @include media-breakpoint-down(sm) {
            margin: 12px 0;
        }

        .navbar-toggler {
            line-height: normal; /* 36/16 */
            height: auto;
        }
    .minicart {
        margin: 0 0 0 24px;
    }
    // 63880
    .user {
        margin: 0 0 0 24px;
        text-transform: uppercase;

        .account-user__icon{
            margin-right: 10px;
            position: relative;
            top: -2px;
        }

        .popover{
            right: 0;
            left: auto;
            max-width: 160px;
        }

        // 25845 start
        .user-message{
            white-space: nowrap;
            overflow: hidden;
            max-width: 180px;
            text-overflow: ellipsis;
            border-radius: 0px;
        }
        // 25845 start
    }
    .header__external-link{
        & > a{
            text-decoration: underline;
            font-family: $primary-font-semibold;
            font-size: 16px;
        }
    }
    .search{
        margin: 0 40px 0 40px;

        input{
            width: 100%;
            padding:12px 80px 12px 21px;
            border-bottom: 2px solid $yellow;
            font-size: 14px;
            line-height: 26px;
            font-weight: normal;
            font-family: $primary-font-light;
            background-color: $light-gray;
            color: $gray;
        }
        .c-search-header__submit {
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            padding:0 13px;
        }
        .reset-button {
            top: 0;
            bottom: 0;
            margin: auto;
            right: 50px;
        }
    }
}

.main-menu {

    border-bottom: 0 none;
    background-color: $primary-dark;
    .container {
        max-width: 100%;

        @include media-breakpoint-up(xxl) {
            max-width: 1200px;
        }
    }

    &.menu-toggleable-left.navbar-toggleable-sm{

        @include media-breakpoint-down(sm) {
            display: none !important;
            &.active{
                display: block !important;
            }
        }
    }
}

body{
    .main-menu {
        border-bottom: 0 none;
        background-color: $primary-dark;        
    }
}
// 75204
.header-top-banner {
    background-color: $pastel-orange;
    color: $black;
    font-family: $primary-font;
    padding: 7px;
    font-size: 15px;
    @media (max-width: 345px) {
        font-size: 12px;
        line-height: 16px;
    }
    // 26357 start
    &.alert{
        padding: 7px 30px 7px 7px;
        border-radius: 0;
        border: 0 none;
        position: relative;
        margin-bottom: 2px;
    }
    
    #promo-slot{
	padding: 10px;
    }
    
    #browser-message{
	padding: 5px 10px 10px 10px;
    }
    // 26357 end
    a {
        color: $black;
        text-decoration: underline;
    }

    p {
        margin-bottom: 0;
    }

    .modal-dialog {
        margin-top:50%;

        @include media-breakpoint-up(md) {
            margin-top: 10%;
        }

        p {
            margin-bottom: 15px;
        }

        .modal-header {
            border: 0;
            padding: 48px 20px 0;
            box-sizing: border-box;
            position: relative;

            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                background : url(../../images/modal-exit.png) no-repeat;
                text-indent: -9999px;
                width: 20px;
                height: 20px;
                background-position: left bottom;
                z-index: 100;
                opacity: 1;
            }
        }
    }
}

.search-mobile{
    .site-search{
        padding: 0;

        input{
            width: 100%;
            padding:12px 80px 12px 21px;
            border-bottom: 2px solid $yellow;
            font-size: 14px;
            line-height: 26px;
            font-weight: normal;
            font-family: $primary-font-light;
            background-color: $light-gray;
            color: $gray;
        }
        .c-search-header__submit {
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            padding:0 13px;
        }
        .reset-button {
            top: 0;
            bottom: 0;
            margin: auto;
            right: 50px;
        }
    }
}