 /* ============================================================================
    Scope Footer
    ============================================================================ */

$ns: s-footer;

.#{$ns} {

    .content-asset {

        ul {
            margin: 0 20px 0 0;

            li {
                line-height: 18px;
                margin-bottom: 16px;

                a{
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 18px;
                    letter-spacing: 0;
                    color: $white;
                    background: $primary;
                }
            }
        }

    }

    .social {
        margin-top: 0;
    }
    hr{
        border-color: $secondary;
        margin-top: 25px;
        margin-bottom: 16px;
    }
}

