$ns: c-module;

$heading-font: $secondary-font-medium;
$subheading-font: $primary-font-light;

$featured_banner-font: $primary-font-semibold;
$featured_banner__heading-font: $secondary-font-black;
$featured_banner-color: $white !default;

$three-col-grid__callout__title-font: $primary-font-bold;
$three-col-grid__callout__sub-font: $primary-font;
$three-col-grid__tile-bg: $bittersweet;
$three-col-grid__tile__title-font: $primary-font;
$three-col-grid__tile__title-font-b: $primary-font-bold;
$three-col-grid__tile__body-font: $primary-font-light;

$tab__list-border: 3px solid $cod;
$tab__link-color: $cod;

$step__count-font: $primary-font-bold;
$step__title-font: $primary-font;
$step__detail-font: $primary-font;

$reqform__sub-font: $primary-font;
$reqform__terms-font: $primary-font-light;

.#{$ns} {
    &--bg-eastern-blue {
        background-color: $eastern-blue;
    }

    &--bg-lagoon {
        background-color: $lagoon;
    }

    &--bg-white {
        background-color: $white;
    }

    &--bg-white-smoke {
        background-color: $white-smoke;
    }

    &--heading-tan {
        color: $tan;
    }

    &--heading-white {
        color: $white;
    }

    &--fullbleed {
        width: 100vw;
        max-width: calc(100vw);
        margin-left: calc((100vw - 100%) / -2);
        overflow: hidden;
        padding: 0;
    }

    &--heading {
        font-family: $heading-font;
        font-size: 23px;
        line-height: 25px;
        text-align: center;

        &:before {
            border-top: 5px solid $bright-orange;
            content: "";
            display: block;
            width: 80px;
            margin: 0 auto 30px;
        }

        @include media-breakpoint-up(md) {
            font-size: 43px;
            line-height: 45px;
            width: 450px;
            margin: 0 auto;
        }
    }

    // Override for Request for Information header
    .request-for-info &--heading {
        @include media-breakpoint-up(md) {
            width: 600px;
        }
    }

    &--subheading {
        font-family: $subheading-font;
        font-size: 16px;
        color: $bright-gray;
        max-width: 260px;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            max-width: 510px;
        }
    }

    /* Featured Banner module*/
    &__featured_banner {
        color: $featured_banner-color;
        font-family: $featured_banner-font;
        position: relative;

        &__text {
            font-family: $featured_banner-font;
            // increasing max width to not wrap button on iphone
            max-width: 200px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                max-width: 800px;
            }
        }

        &__heading {
            font-family: $featured_banner__heading-font;
            font-size: 26px;
            line-height: 1.4;
            font-weight: normal;
            letter-spacing: 1px;

            /*overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;*/

            @include media-breakpoint-up(md) {
                font-size: 38px;
                line-height: 1.4;
                margin-bottom: 32px !important;
            }
            @media (max-width: 1200px) {
                font-size: 35px;
                margin-bottom: 20px !important;
            }
            @media (max-width: 1100px) {
                font-size: 25px;
                margin-bottom: 15px !important;
            }
            @media (max-width: 767px) {
                font-size: 18px;
                margin-bottom: 8px !important;
            }
        }

        &__body {
            font-size: 20px;
            line-height: 34px;
            @media (max-width: 1200px) {
                font-size: 20px;
            }
            @media (max-width: 1100px) {
                font-size: 15px;
                line-height: 24px;
            }
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        &__cta {
            max-width: 180px;
            @include media-breakpoint-up(sm) {
                max-width: 240px;
            }
        }
    }

    /* Three col grid module*/
    &__three-col-grid {
        &__callout {
            margin-top: 50px;
            @include media-breakpoint-up(md) {
                margin-top: 70px;
            }

            &__title {
                font-family: $three-col-grid__callout__title-font;
                font-size: 23px;
                line-height: 25px;
                color: $tan;

                @include media-breakpoint-up(md) {
                    font-size: 43px;
                    line-height: 45px;
                }
            }

            &__sub {
                font-family: $three-col-grid__callout__sub-font;
                font-size: 16px;
                color: $bright-gray;
                // Making a % so it fills the space better in tablet
                max-width: 75%;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                    max-width: 510px;
                }
            }

            &__cta {
                a {
                    color: $primary !important;
                    font-size: 16px !important;
                    text-decoration: underline;

                    @include media-breakpoint-up(md) {
                        font-size: 20px !important;
                    }
                }
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 50px;

            @include media-breakpoint-up(md) {
                margin-top: 70px;
            }
        }

        &__tile {
            width: 48%;
            margin-bottom: 25px;

            @include media-breakpoint-up(md) {
                width: 32%;
                margin-bottom: 60px;
                max-width: 360px;
            }

            &__detail {
                background-color: $three-col-grid__tile-bg;
                color: $white;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;

                @include media-breakpoint-up(md) {
                    min-height: 100px;
                }
            }

            &__title {
                a {
                    font-family: $three-col-grid__tile__title-font;
                    font-size: 15px;
                    color: $white;

                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                        font-family: $three-col-grid__tile__title-font-b;
                    }
                }
            }

            &__body {
                font-family: $three-col-grid__tile__body-font;
                font-size: 16px;
                line-height: 18px;
                width: 80%;
            }
        }
    }

    /* Tab module */
    &__tab {
        margin: 30px 0 50px;

        @include media-breakpoint-up(lg) {
            margin: 70px 0 0;
        }

        &__list {
            border-bottom: $tab__list-border;
            display: flex;
            justify-content: space-around;

            a {
                text-decoration: none;
            }
        }

        &__link {
            padding-bottom: 10px;

            a {
                color: $tab__link-color !important;
                font-size: 18px;
                font-family: $primary;

                @include media-breakpoint-up(md) {
                    font-size: 25px;
                }
            }

            &:hover,
            &.active {
                border-bottom: 3px solid $tan;
                margin-bottom: -3px;

                a {
                    color: $tan !important;
                }
            }

            &:first-child {
                margin-left: 30px;
            }

            &:last-child {
                margin-left: -30px;
            }
        }

        &__wrapper {
            margin-top: 30px;

            @include media-breakpoint-up(lg) {
                margin-top: 70px;
            }
        }

        &__content {
            position: relative;
            display: none;

            &.active {
                display: block;
            }
        }

        &__text {
            position: relative;
            top: 0;
            padding-top: 20px;

            @include media-breakpoint-up(lg) {
                position: absolute;
                padding-top: 0;
            }
        }

        &__img {
            img {
                max-width: 875px !important;
                max-height: 887px;
            }
        }

        &__button {
            color: $tan !important;
            line-height: 26px !important;
            font-family: $primary-font;
            font-weight: 600;
            font-size: 18px;

            span {
                display: block;
            }
        }

        &__accordion {
            border-top: 1px solid $spindle;

            &:last-child {
                border-bottom: 1px solid $spindle;

                @include media-breakpoint-up(lg) {
                    border: none;
                }
            }

            @include media-breakpoint-up(lg) {
                border: none;
            }

            &.active {
                .title {
                    &:after {
                        @include media-breakpoint-down(md) {
                            content: "";
                            background: url("../svg/stack.svg#svg-minus") no-repeat;
                            display: block;
                            height: 10px;
                            width: 14px;
                            margin-top: 4px;
                        }
                    }
                }
            }

            .title {
                &:after {
                    @include media-breakpoint-down(md) {
                        content: "";
                        background: url("../svg/stack.svg#svg-plus") no-repeat;
                        display: block;
                        height: 10px;
                        width: 14px;
                    }
                }
            }
        }

        .active {
            .c-module__tab__content {
                display: block;
            }
        }
    }

    /* Steps module */
    &__steps {
        padding-left: 3px;

        &__item-wrapper {
            padding-left: 20px;
            position: relative;

            &:before {
                content: "";
                background: url("../svg/stack.svg#svg-circle") no-repeat;
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                width: 45px;
                height: 45px;
            }
        }

        &__item {
            padding: 0 0 40px 40px;
            border-left: 4px solid $linkwater;
            max-width: 515px;

            &.no-line {
                border-left: none;
            }
        }

        &__count {
            font-family: $step__count-font;
            font-size: 14px;
            text-transform: uppercase;
            color: $primary;
            padding-bottom: 10px;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }

        &__title {
            color: $tan;
            font-family: $step__title-font;
            font-size: 20px;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-size: 30px;
                padding-bottom: 7px;
            }
        }

        &__detail {
            color: $bright-gray;
            font-family: $step__detail-font;
            font-size: 16px;
            line-height: 28px;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }

            ol {
                padding-left: 15px;
            }
        }
    }

    /* Request form*/
    // 105362
    &__reqform {
        margin: 0px;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
        border: 1px solid $midgray;

        @include media-breakpoint-down(md) {
            box-shadow: none;
            border: 0 none;
        }

        a {
            color: $white;
            text-decoration: underline;
        }

        &__sub {
            font-family: $primary-font-light;
            font-size: 16px;
            max-width: 730px;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 20px;
                line-height: 27px;
            }
        }

        &__terms {
            font-family: $reqform__terms-font;
            font-size: 14px;
            line-height: 18px;
            max-width: 1005px;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 15px;
                line-height: 20px;
            }

            a {
                font-family: $reqform__terms-font;
            }
        }

        &__form {
            margin: 50px auto;

            input {
                &:before {
                    content: "*";
                    color: $red;
                }
            }
        }

        &__submit {
            font-size: 18px;
            font-family: $reqform__sub-font;
        }

        input {
            color: $black;
        }
        // 69927
        .request-form__intro {
            .request-form__intro-wrapper {
                background: $primary;
                padding: 80px;
                letter-spacing: 0;
                color: $white;

                @include media-breakpoint-down(md) {
                    padding: 53px 15px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 24px 15px;
                }

                .c-module--heading {
                    text-align: center;
                    width: auto;
                    margin: 0;
                    font-size: 18px;
                    line-height: 19px;
                    color: $white;
                    font-weight: normal;
                    letter-spacing: 0;
                    @include media-breakpoint-up(md) {
                        text-align: left;
                        font-size: 36px;
                        line-height: 46px;
                    }

                    &:before {
                        display: none;
                    }

                    &:after {
                        content: "";
                        height: 2px;
                        width: 64px;
                        background: $yellow;
                        margin-top: 10px;
                        margin-bottom: 40px;
                        margin-right: auto;
                        margin-left: auto;
                        display: block;
                        @include media-breakpoint-up(md) {
                            margin-top: 16px;
                            margin-bottom: 43px;
                            text-align: left;
                            font-size: 36px;
                            line-height: 36px;
                            margin-left: 0;
                        }
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 16px;
                        }
                    }
                }

                .c-module__reqform__sub {
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $primary-font-light;
                    margin-bottom: 0;
                    text-align: left;
                    max-width: inherit;
                    @include media-breakpoint-down(sm) {
                        text-align: center;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
        .request-form__fields {
            padding: 64px 53px 58px 53px;
            @include media-breakpoint-down(md) {
                padding: 64px 15px 0px 15px;
            }

            @include media-breakpoint-down(sm) {
                padding: 24px 15px 0px 15px;
            }

            .request-info {
                .c-module__reqform__form {
                    margin: 0 0 43px 0;
                    color: $primary;
                    @include media-breakpoint-down(sm) {
                        margin: 0 0 32px 0;

                        & > .row {
                            margin-left: 0;
                            margin-right: 0;

                            > .col,
                            > [class*="col-"] {
                                padding-right: 0;
                                padding-left: 0;
                            }
                        }
                    }
                    // 69356
                    .form-group {
                        position: relative;
                        margin-bottom: 16px;
                        & > label {
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 15px;
                            text-transform: uppercase;
                            position: absolute;
                            top: 24px;
                            left: 0;
                            background-color: $white; // 79715
                        }
                        // 63880
                        .form-control {
                            border: 0 none;
                            border-bottom: 2px solid $primary;
                            padding: 24px 0 16px 0;
                            font-size: 12px;
                            line-height: 15px;
                            height: 59px;
                            // 69359
                            &.is-invalid {
                                border-bottom: 2px solid $red;
                                background-image: none;
                                & + label {
                                    color: $red;
                                }
                            }

                            &:focus,
                            &.filled {
                                & + label {
                                    top: 3px;
                                }
                            }
                        }

                        select.form-control {
                            & + label {
                                top: 3px;
                            }
                        }

                        .c-module__reqform__submit {
                            margin-top: 48px;
                            @include media-breakpoint-down(sm) {
                                margin-top: 25px;
                                width: 100%;
                            }
                        }
                    }
                }
                .c-module__reqform__terms {
                    color: $black;
                    font-family: $primary-font-italic;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: left;
                    &.terms-gutter {
                        margin-top: 20px;
                    }

                    a {
                        font-family: $primary-font-italic;
                        font-style: italic;
                        color: $primary !important;
                    }
                    &-nonusa {
                        &.terms-gutter {
                            margin-top: 20px;
                        }
                        .custom-checkbox-nonusa {
                            padding-left: 0;
                            .rfi-nonusa-checkbox {
                                color: $black;
                                font-family: $primary-font-italic;
                                font-size: 12px;
                                line-height: 18px;
                                text-indent: 1.125rem;

                                a {
                                    text-decoration: underline;
                                    color: $primary !important;
                                    font-family: $primary-font-italic;
                                    font-style: italic;
                                }

                                &:before {
                                    background: $white;
                                    border: 1px solid $input-border-color;
                                    border-radius: 0;
                                    top: 1px;
                                    left: 0;
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 3px;
                                }

                                &:after {
                                    top: 1px;
                                    left: 0;
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 3px;
                                    border-color: $primary;
                                }
                            }
                            .custom-control-input:checked ~ .custom-control-label::after {
                                background-color: $primary;
                                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                            }
                        }
                    }
                }
            }
        }
    }
}

.container.p-0-down-992 {
    @media (max-width: 992px) {
        padding: 0 !important;
    }
}
// 76175
.product-tiles {
    .product-tiles__wrapper {
        .product-tiles__list,
        .product-tiles-recent__list,
        .product-search-result__list {
            margin: 0 -21px;

            @media (max-width: 991px) {
                margin: 0 -11px;
            }
            @media (max-width: 640px) {
                margin: 0 -7px;
            }

            .slick-track {
                display: flex;
                .slick-slide {
                    height: auto;
                    & > div,
                    .tile,
                    .card {
                        height: 100%;
                    }
                    // 82335
                    &[aria-hidden=true] {
                        .skeleton-box {
                            &:after{
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
            // 136341
            .slick-prev {
                @include sliderBtns(left);
                background: url(../../images/arrow-blue-c-left.svg) no-repeat;
                background-size: cover;
                height: 41px;
                width: 22px;
                left: 10px;
                top: 30%;
                z-index: 1;

                &.slick-disabled {
                    opacity: 0.3;
                }

                &:before {
                    display: none;
                }

                @media (min-width: 1331px) {

                    left: -28px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .slick-next {
                background: url(../../images/arrow-blue-c-right.svg) no-repeat;
                background-size: cover;
                height: 41px;
                width: 22px;
                right: 10px;
                top: 30%;
                z-index: 1;


                &.slick-disabled {
                    opacity: 0.3;
                }

                &:before {
                    display: none;
                }

                @media (min-width: 1331px) {
                    right: -28px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
        .tile {
            padding: 0 21px;
            @media (max-width: 991px) {
                padding: 0 11px;
            }
            @media (max-width: 640px) {
                padding: 0 7px;
            }
            // 76193
            .product-badge-type {
                background: url(../../images/parallelogram.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                text-align: center;
                min-width: 80px;
                padding: 0px 20px;
                line-height: 26px;
                font-size: 12px;
                font-family: $primary-font-semibold;
                color: $white;
                position: absolute;
                top: 0;
                right: 20px;
                @include media-breakpoint-down(xs) {
                    right: 6px;
                    min-width: 50px;
                    line-height: 16px;
                    padding: 0px 14px;
                }
            }
            .card {
                border: 1px solid $secondary-light;
                border-radius: 0;
                box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
                margin-bottom: 0;
                color: $secondary-black;
                font-family: $primary-font-semibold;
                // 69358
                img {
                    max-width: 100%;
                    min-height: 80px;
                    @include media-breakpoint-up(sm) {
                        min-height: 150px;
                    }
                    @include media-breakpoint-up(xl) {
                        min-height: 233px;
                    }
                }
                // 69359
                // 95030
                .skeleton-box {
                    overflow: hidden;
                    background-color: $midgray;
                    &.loaded {
                        &:after {
                            display: none;
                        }
                    }
                    &:after,
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                    &:before {
                        background-color: $midgray;  
                    }
                    &:after {
                        transform: translateX(-100%);
                        background-image: linear-gradient(
                          90deg,
                          rgba($white, 0) 0,
                          rgba($white, 0.2) 20%,
                          rgba($white, 0.5) 60%,
                          rgba($white, 0)
                        );
                        animation: shimmer 2s infinite;
                      }
                      @keyframes shimmer {
                        100% {
                          transform: translateX(100%);
                        }
                      }
                      .lozad {
                          &.loaded {
                            animation: fadeInAnimation ease 3s;
                            animation-iteration-count: 1;
                            animation-fill-mode: forwards;
                          }
                          @keyframes fadeInAnimation {
                            0% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                      }
                      img.lozad[src=""],
                      img.lozad:not([src]) {
                        visibility: hidden;
                      }
                }
                .product-badge {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    padding: 12px;
                    color: $secondary-black;
                    background: $yellow;
                    @include media-breakpoint-down(md) {
                        background: rgba($yellow, .85);
                    }
                    font-size: 14px;
                    line-height: 1.2;
                    font-family: $primary-font-extra-bold;
                    @media (max-width: 768px) {
                        font-size: 12px;
                        padding: 2px;
                    }
                }
                // 1373
                .wishlistTile {
                    display: none;
                }
                // 64614
                .card-body {
                    padding: 20px 20px 77px 20px;
                    position: relative;
                    @include media-breakpoint-down(sm) {
                        padding: 10px 10px 35px 10px;
                    }

                    .prod-title {
                        font-family: $primary-font-bold;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 26px;
                        margin-bottom: 16px;
                        font-weight: normal;
                        text-align: left;

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            line-height: 13px;
                            margin-bottom: 10px;
                            text-transform: uppercase;
                        }

                        & > a {
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 26px;

                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                                line-height: 13px;
                            }
                        }
                    }
                    .prod-description {
                        color: $gray;
                        font-size: 16px;
                        line-height: 26px;
                        margin-bottom: 18px;

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            line-height: 13px;
                            margin-bottom: 10px;
                            text-transform: capitalize;
                        }
                    }
                    /*121290*/
                     .product-star-rating-reviews-container {
                        display: flex;
                        flex-flow: row;
                        margin-bottom: 18px;
                        @include media-breakpoint-down(md) {
                            margin-bottom: 10px;
                        }
                        .product-stars {
                            position: relative;
                            display: inline-flex;
                            vertical-align: middle;
                            flex-flow: column;
                            .rating-stars {
                                font-size: 21px;
                                line-height: 21px;
                                display: flex;

                                @include media-breakpoint-down(lg) {
                                    font-size: 18px;
                                    line-height: 18px;
                                }
                                @include media-breakpoint-down(sm) {
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                                &-off {
                                    color: $white;
                                    font-family: $primary-font-light;
                                }
                                &-on {
                                    color: $night-rider;
                                    font-family: $primary-font-bold;
                                    margin-top: -21px;
                                    overflow: hidden;
                                    @include media-breakpoint-down(lg) {
                                        margin-top: -18px;
                                    }
                                    @include media-breakpoint-down(sm) {
                                        margin-top: -12px;
                                    }
                                }
                            }
                        }
                        .psrr {
                            display:inline-flex;
                            font-family: $primary-font-semibold;
                            font-size: 14px;
                            line-height: 21px;
                            vertical-align: bottom;
                            @include media-breakpoint-down(lg) {
                                line-height: 18px;
                            }
                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                                line-height: 10px;
                            }
                        }
                        .product-reviews {
                            color: $black;
                            margin-left: 5px;
                        }
                    }
                    /*121291*/
                    .prod-sticky {
                        position: absolute;
                        left: 0;
                        bottom: 30px;
                        width: 100%;
                        padding: 0 20px;

                        @include media-breakpoint-down(sm) {
                            padding: 0 10px;
                            bottom: 10px;
                        }
                        // 169419
                        .tile-date {
                            font-size: 16px;
                            line-height: 26px;
                            margin-bottom: 0px;
                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                                line-height: 13px;
                            }
                        }
                        .tile-price {
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 26px;

                            * {
                                font-family: $primary-font-bold;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 26px;
                                margin: 0;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                                line-height: 13px;

                                * {
                                    font-size: 12px;
                                    line-height: 13px;
                                }
                            }

                            *,
                            .sr-only {
                                background: $white;
                                color: $secondary-black;
                            }
                            .final-orig-price {
                                .value {
                                    color: $red;
                                }   
                            }
                        }                       
                    }
                    // 169043
                    &.card-discount-info {
                        padding-bottom: 100px;
                        @include media-breakpoint-down(sm) {
                            padding-bottom: 70px;
                        }
                        // 225642
                        .prod-sticky.b-40 {
                            bottom: 40px;
                        }
                        // 225721
                        .price-info {
                            position: absolute;
                            bottom: 0;
                            left:0;
                            width:100%;
                            padding:0px 20px;
                            text-align: right;
                            @include media-breakpoint-down(sm) {
                                padding:0px 10px;
                            }
                            p {
                                font-size: 13px;
                                text-transform: capitalize;
                                @include media-breakpoint-down(md) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    // 169043
                    .strike-through {
                        color: $gray !important;
                        *,
                        .sr-only {
                            background: $white;
                            color: $gray !important;
                        }
                    }
                }
            }

            &.more-tiles {
                .card {
                    background: $secondary-lowlight;
                    .card-body {
                        .more-tiles__link {
                            left: 0;
                            top: 0;
                            .more-description {
                                color: $primary-darkest;
                                font-family: $primary-font-bold;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 45px;
                                @include media-breakpoint-down(sm) {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }
                            .more-title {
                                color: $primary-darkest;
                                font-family: $primary-font-bold;
                                font-weight: bold;
                                font-size: 32px;
                                line-height: 45px;

                                @include media-breakpoint-down(sm) {
                                    font-size: 22px;
                                    line-height: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.common-product-grid {
        .product-tiles__wrapper {
            margin: 0 -21px;
            @media (max-width: 991px) {
                margin: 0 -11px;
            }
            @media (max-width: 640px) {
                margin: 0 -7px;
            }

            .tile {
                max-width: 33.333%;
                width: 33.33%;
                margin-bottom: 40px;

                @media (max-width: 991px) {
                    margin-bottom: 25px;
                }
                @media (max-width: 779px) {
                    max-width: 50%;
                    width: 50%;
                }
                @media (max-width: 640px) {
                    margin-bottom: 15px;
                }
                .card {
                    height: 100%;
                    // 1373
                    .wishlistTile {
                        display: flex;
                    }
                    // 81221
                    .product-badge {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-height: 40px;
                        @media (max-width: 991px) {
                            padding-right: 40px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
// 95030
// 103927
a,
button,
.form-control {
    &:focus {
        outline: none!important;
        box-shadow: none !important;
    }
    &:focus-visible {
        outline: 2px solid #434343 !important;
    }
}

.request-for-info__container {
    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-select {
    overflow: hidden;
    width: 100%;

    select.form-control {
        width: calc(100% + 30px);
        background: url(../../images/arrow-down-blue.svg) no-repeat;
        background-position: right 40px top 30px;
    }
}
.inline-loader {
    margin-bottom: 144px;
    font-family: $primary-font-semibold;
    font-size: 16px;
    color: $primary;
    display: none;
    @media (max-width: 768px) {
        margin-bottom: 63px;
    }
}
// 86868
.h-0 {
    height: 0px!important;
}

.c-filter {
    color: $secondary-black;
    // 54933
    position: relative;
    .c-filter__sort{
        // 78048 Start
        justify-content: right;
         // 78048 End
        // 56062
        @include media-breakpoint-down(sm){
            display: none !important;
        }
        // 56062
    }
    //54933
    .search-refinebar {
        .c-filter__sort{ 
            position: absolute;
            right: 0;
            bottom: -64px;
            @media (max-width: 768px){
                bottom: -60px;
            }
        }
    }
    // 56062
    .c-filter__mobile{
        @include media-breakpoint-down(sm){
            display: flex !important;
        }
        .btn{
            padding:10px 10px 10px 40px;
            font-family: $primary-font-bold;
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            color: $primary !important;
            border: 1px solid $primary-lowlight;
            margin: 0 5px;
            box-shadow: 0px 3px 5px $shadow-blue;
            border-radius: 0;
            width: 50%; 
            text-align: left;
            background-repeat: no-repeat;
            background-position: 10px center;  

            &:hover{
                background-color: $secondary-lowlight;
            }
            
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }

            &.c-btn__filter{
                background-image: url(../../images/ic_filterMobile.svg);                
            }
            &.c-btn__sort{
                background-image: url(../../images/ic_sortMobile.svg);                
            }
        }
    }
    // 56062

    .c-filter__wrapper {
        .c-filter__header {
            margin-bottom: 32px;

            @media (max-width: 768px) {
                margin-bottom: 0px;
            }

            // 56062
            @include media-breakpoint-down(sm){
                display: none !important;
            }
            // 56062

            .filter-header__wrapper {
                // 45204 Start
                .filter-header__title {
                    font-family: $primary-font-extra-bold;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-bottom: 0;

                    @media (max-width: 768px) {
                        font-size: 14px;
                        order: 2;
                        margin-bottom: 20px;
                    }
                }
                // 45204 End
                .c-filter__sort {
                    // 45204 Start
                    &.selected_alignment {
                        right: 0;
                        position: absolute;
                        margin-right: 15px;
                    }
                    // 45204 End
                    @media (max-width: 768px) {
                        width: 100%;
                        order: 1;
                        margin-bottom: 20px;
                    }

                    .custom-select {
                        font-family: $primary-font-extra-bold;
                        font-weight: normal;
                        // 45204 Start
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: capitalize;
                        // 45204 End
                        margin-bottom: 0;
                        padding: 9px 32px 9px 14px;
                        border: 1px solid $gray;
                        height: auto;
                        background: url(../../images/arrow-down-blue.svg) no-repeat;
                        background-position: right 14px center;
                        color: #012934;

                        // 45204 Start
                        &.custom-sort {
                            padding: 9px 32px 9px 5px;
                            background-position: right 10px center;
                            width: 120px;
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                color: $primary;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                        // 45204 End

                        @media (max-width: 768px) {
                            font-size: 14px;
                            padding: 11px 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .c-filter__body {            
            .filter-header__title {
                font-family: $primary-font-extra-bold;
                font-weight: normal;
                font-size: 14px;
                line-height: normal;
                text-transform: capitalize;
                // 54933
                margin-bottom: 6px;
                // 54933
                // 92498
                text-align: left;

                @media (max-width: 768px) {
                    font-size: 14px;
                    order: 2;
                    margin-bottom: 20px;
                }
            }

            // 45204 Start
            .selected_alignment {
                right: 0;
                position: absolute;
                margin-right: 15px;
            }
            &.c-boarder_bottom {
                border-bottom: 2px solid $midgray;
            }
            .refinement {
                margin-bottom: 0;
            }
            // 45204 End
            .c-filter__items {
                .c-filter__list {
                    // 54933
                    padding: 0px;
                    margin-bottom: 24px;
                    // 54933
                    list-style: none;
                    border-bottom: 2px solid $midgray;

                    .c-filter__item {
                        // 54933
                        margin-right: 12px;
                        // 54933
                        margin-bottom: 12px;

                        @media (max-width: 768px) {
                            margin-right: 16px;
                            margin-bottom: 10px;
                        }
                        .appear-select.btn {
                            background-image: url(../../images/arrow-down-blue.svg);
                            background-color: transparent;
                            background-repeat: no-repeat;
                            background-position: right 16px center;
                            padding: 11px 36px 11px 16px;
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            font-size: 14px;
                            // 54933
                            line-height: 16px;
                            // 54933
                            border: 1px solid $gray;
                            color: $primary;
                            height: auto;
                            border-radius: 0;

                            &.selected {
                                background-color: $light-gray;
                            }

                            &[aria-expanded="true"] {
                                background-color: $secondary-lowlight;
                            }

                            @media (max-width: 768px) {
                                padding: 10px 28px 10px 8px;
                                font-size: 12px;
                                background-position: right 8px center;
                            }
                        }
                        .btn.btn-secondary {
                            padding: 11px 32px;
                            height: auto;

                            @media (max-width: 768px) {
                                padding: 10px 32px;
                            }
                        }
                    }
                }
                .c-boarder_bottom_none {
                    border-bottom: none;
                }

                .c-filter__content {
                    &.c-boarder_bottom_none {
                        form {
                            border-bottom: none;
                        }
                    }
                    // 54933
                    padding: 0;
                    // 54933
                    @media (max-width: 768px) {
                        padding: 16px 25px 0 25px;
                    }
                    .c-filter__options-list {
                        padding-bottom: 24px;
                        // 54933
                        border-bottom:0 none;
                        // 54933
                        column-count: 3;
                        // 54933
                        margin-bottom: 0px;
                        // 54933

                        @media (max-width: 768px) {
                            padding-bottom: 16px;
                            column-count: 2;
                        }

                        @media (max-width: 500px) {
                            padding-right: 0;
                        }
                        .c-filter__option {
                            margin-top: 20px;
                            @media (max-width: 768px) {
                                margin-top: 16px;
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                            &.form-group {
                                position: relative;
                                padding: 0;
                                margin-bottom: 0;
                                padding-right: 10px;

                                @media (max-width: 500px) {
                                    padding-right: 0;
                                }

                                label.form-check-label {
                                    font-family: $primary-font;
                                    font-weight: normal;
                                    font-size: 14px;
                                    color: $gray;
                                    margin: 0;
                                    padding-left: 24px;
                                    position: relative;
                                    line-height: normal;

                                    &:before {
                                        display: block;
                                        width: 14px;
                                        height: 14px;
                                        border: 2px solid $primary;
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        top: 1px;
                                        background: transparent;
                                        @media (max-width: 768px) {
                                            top: 0px;
                                        }
                                    }

                                    @media (max-width: 768px) {
                                        font-size: 12px;
                                    }
                                }

                                input {
                                    width: 0;
                                    height: 0;
                                    opacity: 0;
                                    position: absolute;
                                    top: 0;
                                    left: 0;

                                    &:focus {
                                        & + label.form-check-label {
                                            &:before {
                                                outline: 2px solid #434343 !important;
                                                box-shadow: none !important;
                                            }
                                        }
                                    }

                                    &[type="radio"] {
                                        & + label.form-check-label {
                                            &:before {
                                                border-radius: 10px;
                                            }
                                            &:after {
                                                display: block;
                                                width: 8px;
                                                height: 8px;
                                                content: "";
                                                position: absolute;
                                                left: 3px;
                                                top: 4px;
                                                background: transparent;
                                                border-radius: 8px;

                                                @media (max-width: 768px) {
                                                    top: 3px;
                                                }
                                            }
                                        }

                                        &:checked + label.form-check-label {
                                            &:after {
                                                background: $primary;
                                            }
                                        }
                                    }
                                    &[type="checkbox"] {
                                        &:checked + label.form-check-label {
                                            &:before {
                                                background: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .c-filter__selected {
                    // 54933
                    padding: 25px 0px 24px 0px;
                    border-top:  2px solid $midgray;
                    // 54933
                    // 31264
                    @media (max-width: 768px) {
                        padding: 16px 0px 14px 0px;
                    }
                    display: none;
                    .c-filter__selected-wrapper {
                        .c-filter__selected-list {
                            .c-filter__selected-item {
                                margin-right: 20px;
                                margin-bottom: 10px;
                                @media (max-width: 768px) {
                                    margin-right: 16px;
                                }
                                .c-filter__selected-button {
                                    padding: 9px 38px 9px 10px;
                                    font-family: $primary-font-semibold;
                                    font-size: 14px;
                                    font-weight: normal;
                                    line-height: normal;
                                    color: $white;
                                    background: $primary;
                                    white-space: nowrap;

                                    @media (max-width: 768px) {
                                        font-size: 12px;
                                    }

                                    .c-filter__unselect {
                                        -webkit-appearance: none;
                                        width: 12px;
                                        height: 12px;
                                        background-image: url(../../images/icon-filter-close.svg);
                                        background-color: $primary;
                                        color: $white;
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                        background-size: 12px;
                                        overflow: hidden;
                                        text-indent: -9999999px;
                                        display: block;
                                        position: absolute;
                                        right: 10px;
                                        bottom: 0;
                                        top: 0;
                                        margin: auto;
                                        border: 0 none;
                                    }
                                }
                            }
                        }
                        .c-filter__clear {
                            button {
                                -webkit-appearance: none;
                                text-decoration: underline;
                                padding: 0;
                                height: auto;
                                border: 0 none;
                                white-space: nowrap;
                                font-family: $primary-font-semibold;
                                font-size: 16px;
                                font-weight: normal;
                                line-height: normal;
                                color: $primary;
                                background: none;
                                margin-top: 10px;

                                @media (max-width: 768px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body .container .breadcrumb,
body .product-detail.container .breadcrumb {
    border-radius: 0;
    border-bottom: 0 none;
    margin-top: 16px;
    margin-bottom: 48px;
    padding: 0;

    @media (max-width: 768px) {
        margin-bottom: 24px;
    }
    // 165826
    .breadcrumb-item {
        font-family: $primary-font;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.28px;
        color: $black;
        margin: 0;
        padding: 0;

        &:before {
            padding-right: 0;
            color: $black;
        }
        @media (max-width: 768px) {
            font-size: 12px;
        }

        a {
            color: $primary !important;
            padding-right: 4px;
        }
    }
}

body .container.product-detail {
    margin-top: 0;
    margin-bottom: 0;
}

.search-banner,
.pdp-banner {
    // 26274 start
    background: none !important;
    // 26274 end
    &.inner-hero {
        height: 300px;
        width: 100%;
        position: relative;

        @media (max-width: 767px) {
            height: 148px;
        }

        .html-slot-container {
            height: 300px;
            @media (max-width: 767px) {
                height: 148px;
            }
        }
        .inner-hero-bg-image {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .inner-hero-content {
            //  background: rgba(0,0,0,0.36); Story#75717
            background: rgba(0, 59, 76, 0.80);
            color: $white;

            h1 {
                font-family: $inner_banner__heading-font;
                font-size: 26px;
                line-height: 1.4;
                font-weight: normal;
                letter-spacing: 1px;
                text-align: left;

                @include media-breakpoint-up(md) {
                    font-size: 38px;
                    line-height: 1.4;
                    margin-bottom: 24px !important;
                }
                @media (max-width: 1200px) {
                    font-size: 35px;
                    margin-bottom: 20px !important;
                }
                @media (max-width: 1100px) {
                    font-size: 25px;
                    margin-bottom: 15px !important;
                }
                @media (max-width: 767px) {
                    font-size: 18px;
                    margin-bottom: 8px !important;

                    br {
                        display: none;
                    }
                }

                &.hero-product__title {
                    max-width: 800px;
                    margin-bottom: 16px !important;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        margin-bottom: 10px !important;
                    }
                }
            }

            p {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.36;
                font-family: $primary-font;
                letter-spacing: normal;
                text-align: left;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    font-size: 16px;
                    line-height: 24px;
                }

                @media (max-width: 1200px) {
                    max-width: inherit;
                    font-size: 16px;
                    line-height: 24px;
                }

                @media (max-width: 767px) {
                    font-size: 12px;
                    line-height: 16px;
                    br {
                        display: none;
                    }
                }

                &.hero-product__sub-title {
                    font-family: $primary-font-semibold;
                    font-size: 20px;
                    margin-bottom: 10px;

                    @media (max-width: 1200px) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .hero-product__accreditation {
                font-family: $primary-font;
                font-size: 18px;
                font-weight: normal;
                line-height: 1.36;
                font-family: $primary-font;
                letter-spacing: normal;
                text-align: left;

                @media (max-width: 1200px) {
                    font-size: 16px;
                    line-height: 24px;
                }

                @media (max-width: 767px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                img {
                    max-width: 54px;
                    &.graphic-accreditation {
                        max-width: 134px;
                    }
                    @media (max-width: 767px) {
                        max-width: 35px;
                        &.graphic-accreditation {
                            max-width: 70px;
                        }
                    }
                }
            }
            /*121291*/
            .product-star-rating-reviews-container {
                margin-bottom: 10px;
                .product-stars {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    .rating-stars {
                        font-size: 22px;
                        line-height: 22px;
                        display: block;
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                            line-height: 16px;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                            line-height: 14px;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            line-height: 12px;
                        }
                        &-off {
                            color: $white;
                            font-family: $primary-font-light;
                        }
                        &-on {
                            color: $yellow;
                            font-family: $primary-font-bold;
                            margin-top: -22px;
                            overflow: hidden;
                            @include media-breakpoint-down(lg) {
                                margin-top: -16px;
                            }
                            @include media-breakpoint-down(md) {
                                margin-top: -14px;
                            }
                            @include media-breakpoint-down(sm) {
                                margin-top: -12px;
                            }
                        }
                    }
                }
                .psrr {
                    display:inline-block;
                    font-family: $primary-font;
                    &.product-rating {
                        color: $metallic-yellow;
                        font-size: 16px;
                        line-height: 18px;
                        vertical-align: bottom;
                        @include media-breakpoint-down(lg) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                    &.product-reviews {
                        color: $white;
                        font-size: 14px;
                        line-height: 20px;
                        vertical-align: bottom;
                        letter-spacing: .2px;
                        @include media-breakpoint-down(lg) {
                            line-height: 16px;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
}

.layout__two-col {
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .two-col__left {
        margin-right: 40px;
        width: calc(100% - 300px);

        @media (max-width: 860px) {
            width: calc(100% - 250px);
        }

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
        }

        .pdp-tab {
            margin-bottom: 17px;

            @media (max-width: 768px) {
                margin-bottom: 10px;
            }

            ul {
                border-bottom: 1px solid $primary;
                @media (max-width: 768px) {
                    border-bottom: 0;
                }
                li {
                    margin-right: 49px;

                    @media (max-width: 1200px) {
                        margin-right: 30px;
                    }

                    @media (max-width: 768px) {
                        margin-right: 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        padding: 8px 0;
                        text-decoration: none;
                        font-family: $primary-font-bold;
                        font-weight: bold;
                        font-size: 18px;
                        color: $gray !important;
                        border-bottom: 3px solid transparent;
                        position: relative;
                        bottom: -2px;

                        &:active,
                        &:focus,
                        &:hover,
                        &.active {
                            border-bottom: 3px solid $yellow;
                        }

                        @media (max-width: 768px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        /***************** PDP Common Styles *****************/
        color: $black;
        font-family: $primary-font;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: 768px) {
            font-size: 12px;
            line-height: 18px;
        }

        section {
            margin-bottom: 40px;

            @media (max-width: 768px) {
                margin-bottom: 32px;
            }

            &.section-features {
                margin-bottom: 0;
                &.section-featured {
                    margin-bottom: 40px;
                }

                @media (max-width: 768px) {
                    margin-bottom: 18px;
                }
            }

            &.section-awards {
                margin-bottom: 0;

                @media (max-width: 768px) {
                    margin-bottom: 12px;
                }
            }
        }

        .article {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            // 57385
            h3 {
                text-align: left;
            }
            
            .media {
                @media (max-width: 400px) {
                    flex-direction: column;

                    img {
                        margin-right: 0 !important;
                        margin-bottom: 16px;
                        -ms-flex-item-align: flex-start !important;
                        -ms-grid-row-align: flex-start !important;
                        align-self: flex-start !important;
                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                        }
                    }
                }
            }
            &.article-course-delivery-method {
                margin-bottom: 16px;
            }
            // 261603
            .l1-course-descriptions { 
                p {
                    font-family: $primary-font;
                    font-size: 13px;
                    line-height: 20px;
                    color: $night-rider;
                    font-style: italic;
                    padding: 20px 0px 10px;
                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                        padding: 15px 0px 0px;
                    }
                }
            }
        }
        // 170830
        h1,
        h2,
        h1.primary-heading,
        h2.primary-heading,
        .styled-text {
            font-family: $primary-font-extra-bold;
            font-size: 18px;
            line-height: normal;
            font-weight: normal;
            color: $secondary-black;
            margin-bottom: 24px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        h3,
        h4,
        h5,
        h6,
        h1.secondary-heading,
        h2.secondary-heading,
        .styled-text {
            font-family: $primary-font-bold;
            font-size: 16px;
            line-height: normal;
            font-weight: bold;
            color: $secondary-black;
            margin-bottom: 24px;
            @media (max-width: 768px) {
                font-size: 12px;
                margin-bottom: 16px;
            }
        }

        p {
            margin-bottom: 24px;

            @media (max-width: 768px) {
                margin-bottom: 16px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        ol {
            margin-bottom: 24px;
            @media (max-width: 768px) {
                margin-bottom: 16px;
            }

            li {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
        ul:not(.pdp-tab-list) {
            list-style: none;
            padding: 0;
            margin-bottom: 24px;

            @media (max-width: 768px) {
                margin-bottom: 16px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            li {
                font-family: $primary-font;
                font-weight: normal;
                font-size: 16px;
                line-height: normal;
                color: $black;
                margin-bottom: 8px;
                padding-left: 22px;
                position: relative;

                @media (max-width: 768px) {
                    font-size: 12px;
                }

                &:before {
                    position: absolute;
                    left: 0;
                    content: "";
                    width: 6px;
                    height: 6px;
                    background: $primary;
                    border-radius: 3px;
                    top: 8px;
                    color: $white;
                    @media (max-width: 768px) {
                        top: 4px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: $primary !important;
                    font-family: $primary-font-semibold;
                    text-decoration: underline;
                }

                ol,
                ul {
                    margin-top: 8px;
                    margin-bottom: 16px !important;
                }
            }

            &.course-method-styled {
                padding-bottom: 3px;
                & > li {
                    padding-left: 45px;
                    margin-bottom: 22px;

                    @media (max-width: 768px) {
                        margin-bottom: 18px;
                        padding-left: 30px;
                    }

                    &:last-child {
                        margin-left: 30px;

                        @media (max-width: 768px) {
                            margin-left: 0px;
                        }
                    }

                    &:before {
                        width: 35px;
                        height: 35px;
                        border-radius: 20px;
                        border: 1px solid $secondary-light;
                        background-color: $secondary-lowlight;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
                        background-repeat: no-repeat;
                        background-position: center center;
                        top: -7px;

                        @media (max-width: 768px) {
                            width: 20px;
                            height: 20px;
                            background-size: 8px;
                        }
                    }
                    &.video-based,
                    &.live-virtual {
                        margin: 0px 30px 24px 0px;
                        @media (max-width: 768px) {
                            padding-left: 40px;
                            &:before {
                                width: 28px;
                                height: 28px;
                                background-size: 14px;
                                top: -3px;
                            }
                        }
                    }
                    &.video-based {
                        &:before {
                            background-image: url(../../images/icon-online-keyboard.svg);
                        }
                    }
                    &.live-virtual {
                        margin: 0px 30px 24px 0px;
                        &:before {
                            background-image: url(../../images/icon-live-classroom.svg);
                            @media (max-width: 768px) {
                                margin-bottom: 0px;
                            }
                        }
                    }

                    ol,
                    ul {
                        margin-top: 18px;
                        @media (max-width: 768px) {
                            margin-top: 16px;
                        }
                    }
                }
            }

            &.check-styled {
                padding-bottom: 3px;
                & > li {
                    padding-left: 42px;
                    margin-bottom: 22px;

                    @media (max-width: 768px) {
                        margin-bottom: 18px;
                        padding-left: 30px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:before {
                        width: 26px;
                        height: 26px;
                        border-radius: 20px;
                        background-image: url(../../images/icon-list-check.svg);
                        background-color: $primary;
                        background-repeat: no-repeat;
                        background-position: center center;
                        top: -3px;

                        @media (max-width: 768px) {
                            width: 20px;
                            height: 20px;
                            background-size: 8px;
                        }
                    }

                    &.curriculam-mode {
                        &:before {
                            width: 28px;
                            height: 28px;
                            background-image: url(../../images/icon-curriculam-mode.svg);
                        }
                        @media (max-width: 768px) {
                            padding-left: 40px;
                            &:before {
                                background-size: 100% 100%;
                            }
                        }
                    }

                    ol,
                    ul {
                        margin-top: 18px;
                        @media (max-width: 768px) {
                            margin-top: 16px;
                        }
                    }
                }
                &.curriculam-check-styled {
                    margin-top: 3px;
                    margin-bottom: 3px !important;
                }
            }

            &.timeline-styled {
                margin-bottom: 24px;

                & > li {
                    padding-bottom: 33px;
                    font-family: $primary-font;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                    position: relative;
                    padding-left: 60px;
                    padding-top: 1px;
                    margin-bottom: 0;
                    padding-bottom: 45px;

                    @media (max-width: 768px) {
                        font-size: 12px;
                        padding-bottom: 24px;
                        padding-left: 32px;
                    }

                    &:before {
                        content: "\2022";
                        width: 28px;
                        height: 28px;
                        position: absolute;
                        top: -2px;
                        left: 0;
                        border: 1px solid $secondary-light;
                        background-color: $secondary-lowlight;
                        z-index: 1;
                        border-radius: 18px;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
                        color: $primary;
                        text-align: center;
                        line-height: 22px;
                        font-size: 22px;

                        @media (max-width: 768px) {
                            top: -3px;
                            width: 22px;
                            height: 22px;
                            line-height: 17px;
                            font-size: 22px;
                        }
                    }

                    &:after {
                        content: "";
                        height: 97%;
                        width: 0px;
                        border-left: 1px solid $primary;
                        position: absolute;
                        top: 24px;
                        left: 14px;
                        z-index: 0;
                        @media (max-width: 768px) {
                            display: none;
                        }
                    }

                    &:last-child {
                        padding-bottom: 0;

                        &:after {
                            display: none;
                        }
                    }
                    // 50637
                    .styled-text, h3 {
                        margin-bottom: 16px;
                        color: $primary;
                    }
                    
                    // 50637
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .feature-tiles {
            .feature-tiles__wrapper {
                margin-left: -20px;
                margin-right: -20px;
                @media (max-width: 1140px) {
                    margin-left: -10px;
                    margin-right: -10px;
                }
                @media (max-width: 768px) {
                    margin-left: -7px;
                    margin-right: -7px;
                }
                .tile {
                    max-width: 33.333%;
                    width: 33.33%;
                    margin-bottom: 40px;
                    padding-right: 20px;
                    padding-left: 20px;

                    @media (max-width: 1140px) {
                        padding-right: 10px;
                        padding-left: 10px;
                    }
                    @media (max-width: 992px) {
                        max-width: 50%;
                        width: 50%;
                    }
                    @media (max-width: 768px) {
                        max-width: 33.333%;
                        width: 33.33%;
                        margin-bottom: 14px;
                        padding-right: 7px;
                        padding-left: 7px;
                    }
                    @media (max-width: 580px) {
                        max-width: 50%;
                        width: 50%;
                    }
                    .card {
                        border-radius: 0;
                        border: 1px solid $secondary-light;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
                        margin: 0;

                        .card-body {
                            padding: 22px 16px;

                            @media (max-width: 768px) {
                                padding: 20px 8px;
                            }

                            .tile-icon {
                                padding: 18px 0 40px 0;
                                min-height: 66px;

                                @media (max-width: 768px) {
                                    padding: 2px 0 24px 0;
                                    min-height: 26px;
                                }

                                img {
                                    height: 66px;
                                    @media (max-width: 768px) {
                                        height: 26px;
                                    }
                                }
                            }
                            // 50637
                            .tile-content {
                                h3 {
                                    color: $primary;
                                }
                            }
                            // 50637
                        }
                    }
                }
            }
        }

        .awards-tiles {
            .awards-tiles__wrapper {
                margin-left: -20px;
                margin-right: -20px;
                @media (max-width: 1140px) {
                    margin-left: -10px;
                    margin-right: -10px;
                }
                @media (max-width: 768px) {
                    margin-left: -7px;
                    margin-right: -7px;
                }
                .tile {
                    margin-bottom: 40px;
                    padding-right: 20px;
                    padding-left: 20px;

                    @media (max-width: 1140px) {
                        padding-right: 10px;
                        padding-left: 10px;
                    }

                    @media (max-width: 1060px) {
                        max-width: 325px;
                        width: 50%;
                    }
                    @media (max-width: 768px) {
                        max-width: 50%;
                        width: 50%;
                        margin-bottom: 14px;
                        padding-right: 7px;
                        padding-left: 7px;
                    }

                    .card {
                        border-radius: 0;
                        border: 1px solid $secondary-light;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
                        margin: 0;
                        width: 325px;

                        @media (max-width: 1060px) {
                            width: 100%;
                        }

                        .card-body {
                            padding: 0;
                            background: $primary;
                            color: $white;

                            @media (max-width: 768px) {
                            }

                            .tile-image {
                                width: 100%;
                                padding: 16px 16px 20px 16px;
                                background: $white;
                                color: $white;

                                @media (max-width: 768px) {
                                    padding: 4px;
                                }

                                img {
                                    max-width: 100%;
                                }
                            }

                            .tile-content {
                                background: $primary;
                                padding: 32px 20px;

                                @media (max-width: 768px) {
                                    padding: 16px 4px;
                                }
                                p {
                                    color: $white;
                                    font-family: $primary-font-bold;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }

        &:not(.L3-product) {
            .cart-box.mobile {
                @media (max-width: 768px) {
                    padding-bottom: 24px;
                    border-bottom: 1px solid $primary;
                }
            }
        }
    }
    .two-col__right {
        width: 300px;
        min-width: 300px;

        @media (max-width: 860px) {
            width: 250px;
            min-width: 250px;
        }

        @media (max-width: 768px) {
            width: 100%;
            min-width: inherit;
        }
        .how-to-enroll {
            background: $white-smoke;
            padding: 24px;
            margin-bottom: 32px;

            @media (max-width: 768px) {
                margin-bottom: 14px;
            }

            h3,
            h1.secondary-heading {
                font-family: $primary-font-bold;
                font-weight: bold;
                font-size: 16px;
                line-height: normal;
                margin-bottom: 28px;
                color: $secondary-black;

                @media (max-width: 768px) {
                    font-size: 12px;
                    margin-bottom: 16px;
                }
            }

            ul {
                margin-bottom: 24px;

                li {
                    padding-bottom: 33px;
                    font-family: $primary-font;
                    font-size: 16px;
                    line-height: 24px;
                    color: $black;
                    position: relative;

                    @media (max-width: 768px) {
                        font-size: 12px;
                        padding-bottom: 20px;
                    }

                    &:after {
                        content: "";
                        height: 97%;
                        width: 0px;
                        border: 1px dashed $primary;
                        position: absolute;
                        top: 11px;
                        left: 12px;
                        z-index: 0;
                    }
                    .step-number {
                        font-family: $primary-font-bold;
                        font-weight: bold;
                        font-size: 14px;
                        display: block;
                        width: 26px;
                        height: 26px;
                        background: $primary;
                        color: $white;
                        text-align: center;
                        line-height: 26px;
                        border-radius: 20px;
                        margin-right: 16px;
                        min-width: 26px;
                        position: relative;
                        top: -1px;
                        z-index: 1;
                    }

                    &:last-child {
                        padding-bottom: 0;

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .other-option {
                .option-divider {
                    font-family: $primary-font-bold;
                    font-size: 16px;
                    line-height: bold;
                    color: $secondary-black;
                    margin-bottom: 16px;

                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
                .option-description {
                    font-family: $primary-font-semibold;
                    font-size: 16px;
                    line-height: 26px;
                    color: $secondary-black;

                    @media (max-width: 768px) {
                        font-size: 12px;
                        line-height: 18px;
                    }

                    a {
                        color: $primary !important;
                        font-family: $primary-font-semibold;
                        text-decoration: underline;
                    }
                }
            }
        }
        .who-is-this-for {
            padding: 0 24px;
            margin-bottom: 48px;
            h3,
            h1.secondary-heading {
                font-family: $primary-font-bold;
                font-weight: bold;
                font-size: 16px;
                line-height: normal;
                margin-bottom: 28px;
                color: $secondary-black;

                @media (max-width: 768px) {
                    font-size: 12px;
                    margin-bottom: 16px;
                }
            }
            ul {
                li {
                    font-family: $primary-font;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: normal;
                    color: $black;
                    margin-bottom: 8px;
                    padding-left: 22px;
                    position: relative;

                    @media (max-width: 768px) {
                        font-size: 12px;
                    }

                    &:before {
                        position: absolute;
                        left: 0;
                        content: "";
                        width: 6px;
                        height: 6px;
                        background: $primary;
                        border-radius: 3px;
                        top: 8px;

                        @media (max-width: 768px) {
                            top: 4px;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .cart-box {
        background: $white;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
        border: 1px solid $secondary-light;
        padding: 24px;
        margin-bottom: 32px;
        color: $secondary-black;

        @media (max-width: 768px) {
            margin-bottom: 24px;
        }

        &.mobile {
            @media (min-width: 769px) {
                display: none;

                .cart-box__start-date label,
                .cart-box__start-date input {
                    pointer-events: none;
                }
            }

            padding: 0;
            border: 0 none;
            box-shadow: none;

            .go-to-enroll {
                text-decoration: underline;
                color: $primary !important;
                font-family: $primary-font-semibold;
            }
        }
        &.desktop {
            .l3-pdp__rail {
                height: 0px;
            }
            @media (max-width: 768px) {
                display: none;

                .cart-box__start-date label,
                .cart-box__start-date input {
                    pointer-events: none;
                }
            }

            .go-to-enroll {
                display: none;
            }
        }
        .cart-box__start-date {
            margin-bottom: 14px;

            @media (max-width: 768px) {
                margin-bottom: 12px;
            }

            h2, h3 {
                font-family: $primary-font-bold;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                line-height: normal;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            .alert {
                padding: 5px 10px;
                line-height: normal;
                font-size: 12px;
                font-family: $primary-font-semibold;
                display: none;
            }
            .start-date__wrapper {
                .s-date {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-right: 10px;

                    @media (max-width: 768px) {
                        margin-bottom: 8px;
                    }

                    input {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0;
                        height: 0;
                        opacity: 0;

                        & + label {
                            border: 1px solid $gray;
                            border-radius: 50px;
                            padding: 9px 15px;
                            font-family: $primary-font-bold;
                            font-weight:bold;
                            font-size: 14px;
                            line-height: normal;
                            background: $white;
                            color: $primary;
                            cursor: pointer;

                            &:hover {
                                border-color: $primary;
                            }
                        }

                        &:checked + label {
                            background: $light-gray;
                        }
                    }
                }
                // Story ID 26013 Start
                @media (min-width: 769px) {
                    .s-date:nth-child(2n) {
                        margin-right: 0;
                    }
                }
                // Story ID 26013 End
            }
        }

        .cart-box__price {
            margin-bottom: 20px;
            // 223618
            .price-info {
                color: $red;
                font-size: 14px;
                text-transform: capitalize;
            }
            .price {
                .value {
                    color: $secondary-black;
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 0;

                    @media (max-width: 768px) {
                        font-size: 18px;
                    }
                }
                .final-orig-price {
                    .value {
                        color: $red;
                    }
                }
                // 61642
                // 261624
                .strike-through {
                    .value {
                        color: $gray;
                    }
                }
                // 61642
            }
        }
        .cart-box__add-tocart {
            margin-bottom: 24px;

            @media (max-width: 768px) {
                position: fixed;
                background: $white;
                color: $secondary-black;
                width: 100%;
                left: 0;
                bottom: 0;
                padding: 8px 16px;
                z-index: 3;
                margin: 0;
            }
        }
        .cart-box__product-features {
            ul {
                li {
                    margin-bottom: 20px;
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: normal;
                    padding-left: 0;
                    color: $secondary-black;

                    @media (max-width: 768px) {
                        font-size: 14px;
                        margin-bottom: 16px;
                    }

                    &:before {
                        display: none;
                    }

                    .feature-icon {
                        min-width: 30px;
                        margin-right: 16px;
                        img {
                            display: block;
                            max-width: 100%;
                            margin: 0 auto;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .productId {
            .btn-to-wish-list {
                &.btn-outline-primary {

                    @include media-breakpoint-down(lg) {
                        background-color: transparent;
                        border: 2px solid #00687F;
                        color: #00687F!important;
                        text-transform: uppercase;
                    }
                }
                // 86401
                &.removeFromWishlist.wishlist-product-isadded {
                    opacity: .65;
                    cursor: pointer;
                    &:hover,
                    &:focus,
                    &:active {
                        opacity: .65;
                    }
                    .fa-heart {
                        color: #ff0000;
                    }
                }
            }
        }
    }
    // 240678
    .pdp-promotions {
        display: none;
    } 
}
// 76753
%pdpcoursestiles {
    padding: 64px 0 70px 0;
    background: $light-gray;
    color: $secondary-black;
    .wishlistTile {
       display: none!important; 
    }
    @media (max-width: 768px) {
        margin-top: 0;
        padding: 24px 0;
        margin-bottom: 48px;
    }

    h2,
    h1.primary-heading,
    h2.primary-heading {
        font-family: $primary-font-extra-bold;
        font-weight: normal;
        line-height: normal;
        font-size: 18px;
        margin-bottom: 32px;
        margin-top: 0;

        @media (max-width: 768px) {
            font-size: 14px;
            margin-bottom: 24px;
        }
    }
}

.pdp-recommended-courses {
    @extend %pdpcoursestiles;
    // 123055
    width: 100%;
    overflow-x: hidden;
    margin-bottom: 130px;
    margin-top: 50px;
    .product-tiles__wrapper {
        .tile:nth-child(3n) {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}

.pdp-recently-viewed+.pdp-recommended-courses {
    margin: 0px;
    padding-top: 0px;
}

.pdp-recently-viewed {
    .wishlistTile {
        display: none!important; 
     }
    @extend %pdpcoursestiles;
    .slick-track {
        margin-left: 0px!important;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 0px;
    }
    // 76753
    .slick-arrow.slick-disabled {
        display: none!important;
    }
}
// 76753
.page-pdp footer .back-to-top {
    bottom: 70px;
}
// 25513 start
.awards-tiles {
    img {
        image-rendering: -webkit-optimize-contrast;
    }
}
// 25513 end
// 50637
.section-things-to-know {
    h1.primary-heading,
    h2,
    h3 {
        font-family: $primary-font-extra-bold;
        font-size: 18px;
        line-height: normal;
        font-weight: normal;
        color: $secondary-black;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            font-size: 14px;
        }
    }
    // 50637
    .card {
        background: #f1fafc;
        @media (max-width: 768px) {
            margin-left: -15px;
            margin-right: -15px;
        }
        &__card {
            &-header {
                &__span {
                    color: #012934;
                    font-weight: 600;
                    padding: 4px;
                    font-size: 16px;
                    line-height: 26px;
                    text-transform: capitalize;
                    &:focus {
                        outline: 0px !important;
                        .btn-icon-toggle {
                            outline: 2px solid #434343;
                        }
                    }
                }
            }
            &-body__answer {
                color: #003b4c;
                font-size: 16px;
                line-height: 26px;
                &__padding {
                    padding: 0px 34px 24px 16px;
                }
            }
        }
        &-header {
            background: none;
            padding: 12px;
            .card-collapse {
                .icon-minus {
                    display: inline-block;
                    background: url(../../images/icon-minus.svg) no-repeat;
                    background-size: 100%;
                    width: 17px;
                    height: 17px;
                }
                .icon-plus {
                    display: none;
                }
                &.collapsed {
                    .icon-minus {
                        display: none;
                    }
                    .icon-plus {
                        display: inline-block;
                        background: url(../../images/icon-plus.svg) no-repeat;
                        background-size: 100%;
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
        &__border {
            border: 1px solid #d9eaef;
            margin-bottom: 20px !important;
            &.shadow {
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15) !important;
            }
        }
    }
    .question-container {
        &__margin-top {
            margin-top: 15px;
        }
        &__padding {
            padding-left: 0;
            padding-right: 0;
        }
        &__header {
            &__padding {
                padding-left: 32px;
                &__margin {
                    margin-top: 0px;
                    margin-bottom: 25px;
                }
            }
        }
    }
    .learn-more-container {
        padding: 8px 16px 20px 0px;
        .btn-link {
            font-family: $primary-font-semibold;
            font-size: 16px;
            line-height: 26px;
            color: $primary !important;
            text-decoration: underline;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}
.display-payment-plan-desktop {
    @media (max-width: 767.98px) {
        display: none;
    }
}
// 44590
// 51672
// 54025
// 55126
// 55351
// 49178
// 47551
// 57517
%linebar-heading {
    text-align: center;
    width: auto;
    margin: 0;
    font-family: $secondary-font-medium;
    font-size: 18px;
    line-height: 19px;
    color: $primary-darkest;
    font-weight: normal;
    letter-spacing: 0;
    @include media-breakpoint-up(md) {
        text-align: center;
        font-size: 36px;
        line-height: 46px;
    }
    
    &:after{
        content:'';
        height: 2px;
        width:64px;
        background: $yellow;
        margin-top:10px;
        margin-bottom: 40px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        @include media-breakpoint-up(md) {
            margin-top:16px;
            margin-bottom: 43px;
        }
        @include media-breakpoint-down(sm){
            margin-bottom: 16px;
        }
    }
    }
%block-ellipsis {
    @media (min-width: 1025px) {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        height: 78px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
    }
    
}
%video-thumbnail {
    .video-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .btn-video-popup {
        background: url("../../images/play-video.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
        border: 0px;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

%video-modal-close {
    .close {
        font-family: $primary-font-light;
        color: $primary;
        opacity: 1;
        @media (max-width:767.98px) {
            color: $white;
        }
    }
}

%video-model-small-device {
    @media (max-width:767.98px) {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        max-width: 100%;

        .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
            border: 0px;
            padding-top: 50px;
            background: rgba(0,0,0,.65);
        }
    }
}
// 89347
.section-product-video {
    .experience-commerce-videos {
        display: flex;
        flex-direction: row;
        padding: 0px;
        margin-bottom: 36px;
        @media (max-width: 1024px) {
            flex-direction: column;
        }

        @include media-breakpoint-down(lg) {
            margin: 0px -15px 24px;
        }

        %video-secondary-heading {
            color: $secondary-black;
            font-family: $primary-font-bold;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 8px;
            @media (max-width:767.98px) {
                font-size: 16px;
            }
        }

        %video-secondary-para {
            font-family: $primary-font;
            font-size: 14px;
            line-height: 26px;
            @media (max-width:767.98px) {
                font-size: 12px;
            }
        }
        // 55126
        .col-video {
            width: 100%;
            max-width: 320px;
            display: inline-flex;
            @media (min-width: 768px) and (max-width: 1024px) {
                max-width: 400px;
            }

            @media (max-width:767.98px) {
                max-width: 100%;
            }

            .product-video-thumbnail {
                position: relative;
                overflow: hidden;
                width: 100%;

                @extend %video-thumbnail;
            }
        }

        .col-description {
            width: calc(100% - 320px);
            display: inline-flex;
            border: 1px solid $secondary-light;
            text-align: left;
            padding: 20px;
            flex-flow: column;
            @media (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                max-width: 400px;
            }
            @media (max-width:767.98px) {
                max-width: 100%;
                width: 100%;
                padding: 20px;
            }
            .secondary-heading {
                @extend %video-secondary-heading;
                @media (min-width: 1025px) {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    max-height: 52px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    /* autoprefixer: off */
                }
            }

            p {
                @extend %video-secondary-para;
                &.block-ellipsis {
                    @extend %block-ellipsis;
                }
            }
        }
    }
        .modal {
            @media (max-width:767.98px) {
                padding: 0px!important;
            }
            .modal-dialog {
                &.modal-lg {
                    max-width: 750px;
                    .modal-content {
                        .modal-header {
                            @extend %video-modal-close;
                        }
                        .modal-body {
                            padding: 16px 32px;

                            @media (max-width:767.98px) {
                                padding: 16px 0px;
                                color: $white;
                            }
                            .secondary-heading {
                                @extend %video-secondary-heading;
                                margin-top: 20px;
                                @media (max-width:767.98px) {
                                    padding: 0 16px;
                                    color: $white;
                                }
                            }

                            p {
                                @extend %video-secondary-para;
                                padding-bottom: 10px;
                                @media (max-width:767.98px) {
                                    padding: 0 16px;
                                    color: $white;
                                    opacity: .85;
                                }
                            }
                        }
                    }
                }
             @extend %video-model-small-device;
            }
        }
    // 89347
    // 44590
    // 51672
    // 54025
    // 55351
    .homepage-product-video {
        &.experience-commerce-videos {
            margin-bottom: 0px;
            padding: 30px 0px;
            @include media-breakpoint-down(sm) {
                padding: 20px 0px;
            }
            // 55338
            @media (min-width:768px) and (max-width: 1024px) {
                flex-direction: row;
            }

            %col-asset {
                width: 50%;
                max-width: 100%;
                @media (max-width:767.98px) {
                    max-width: 100%;
                    width: 100%;
                }
            }
            // 89347
            .col-video {
                @extend %col-asset;
                .product-video-thumbnail {
                    @include media-breakpoint-up(md) {
                        max-height: 260px;
                    }
                    @include media-breakpoint-up(xl) {
                        max-height: 340px;
                    }
                    .product-thumbnail {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    .btn-video-popup {
                        background: url("../../images/play-video-lg.svg");
                        width: 57px;
                        height: 56px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        @media (max-width:767.98px) {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }
            }

            .col-description {
                @extend %col-asset;
                border: 0px;
                padding: 0px 50px;
                @media (max-width:1024px) {
                    padding: 20px;
                }
            }

            .hpv-description {
                font-family: $secondary-font-medium;
                font-size:18px;
                line-height: 32px;
                color: $primary-darkest;
                margin: 0px;
                @media (min-width:768px) and (max-width:1024px) {
                    font-size: 14px;
                    line-height:26px;
                }
                @media (max-width:767.98px) {
                    font-size: 12px;
                    line-height:26px;
                }
            }

            .modal-dialog {
                &.modal-lg {
                    .modal-content {
                        .modal-body {
                            p.hpv-description {
                                font-family: $secondary-font-medium;
                            }
                        }
                    }
                }
            }

        }
    }
}
// 54025
// 55351
// 58171
.text-underline{
    text-decoration: underline !important;
}
// 78079
// 82335
.component-gutter {
    padding: 30px 0px;
    @include media-breakpoint-down(sm) {
        padding: 20px 0px;
    }
}
// 170054
.custom-form-radio {
    label.form-check-label {
        font-family: $primary-font;
        font-weight: normal;
        font-size: 16px;
        color: $dark-charcoal;
        margin: 0;
        padding-left: 26px;
        position: relative;
        line-height: 20px;

        &:before {
            display: block;
            width: 18px;
            height: 18px;
            border: 2px solid $primary;
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            background: transparent;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    input {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:focus-visible {
            & + label.form-check-label {
                &:before {
                    outline: 2px solid #434343 !important;
                    box-shadow: none !important;
                }
            }
        }

        &[type="radio"] {
            & + label.form-check-label {
                &:before {
                    border-radius: 10px;
                }
                &:after {
                    display: block;
                    width: 10px;
                    height: 10px;
                    content: "";
                    position: absolute;
                    left: 4px;
                    top: 5px;
                    background: transparent;
                    border-radius: 8px;
                }
            }

            &:checked + label.form-check-label {
                &:after {
                    background: $primary;
                }
            }
        }
        &[type="checkbox"] {
            &:checked + label.form-check-label {
                &:before {
                    background: $primary;
                }
            }
        }
    }
}