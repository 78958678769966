.hero {
    height: 5vw;
    min-height: 100px;
}

h1.page-title {
    background-color: transparent;
    color: $primary;
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    @include media-breakpoint-up(xl) {
        left: calc((100% - 1230px) / 2);
    }

    &::before {
        background-color: transparent;
    }
}
