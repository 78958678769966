@import '~base/productCard';

.line-item-name {
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: 600;         
}

.line-item-pricing-info {
    + .price {
        font-family: $primary-font;
        font-size: 1rem;
        font-weight: 400;
    }
}

.pricing {
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: 400;
}
// 261624
// 268515
.line-item-promo {
    text-align: right;
    color: $bright-green;
    font-size: 14px;
    font-family: $primary-font-semibold;
    margin-top: 10px;
    .icon-discount-coupon:before {
        content: '';
        display:inline-block;
        background-image: url('../../images/icon-discount-coupon.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 18px;
        height: 18px;
        margin-bottom: -4px;
    }
    .discount-coupon-code {
        font-family: $primary-font-bold;
        margin:0px 4px;
    }
    // 273932
    @include media-breakpoint-down(md) {
        padding-left: 75px;
        font-size: 12px;
    }
}