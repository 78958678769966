/* ============================================================================
    Global Footer Structure
    ============================================================================ */

$ns: st-global-footer;
$footer-social-border: 1px solid $secondary;

.#{$ns} {
    font-family: $footer-font;
    font-size: 12px;
    padding: 30px 0;
    color: $white;

    @include media-breakpoint-up(md) {
        font-size: 12px;
        padding: 48px 0;
    }
    
    &__social {
        
        margin: 15px auto;
        
        justify-content: center;
        flex-direction: column;


        @include media-breakpoint-up(md) {
            border: none;
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        @media (max-width: 768px){
            margin-bottom: 24px;
        }
        
        &__follow {
            margin-bottom: 15px;
            text-transform: uppercase;
            background: $primary;

            @include media-breakpoint-up(md) {
                margin-right: 24px;
                margin-bottom: 0;
            }
        }

        &__links {
            margin: 0 15px 0 0;

            &:last-of-type {
                margin-right: 0;
            }
        }

        a {
            font-size: 16px;
        }
    }
}
