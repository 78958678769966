@import '~base/checkout/checkout';
@import 'variables';

.order-thank-you-msg {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 1.25;
    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}

.order-thank-you-email-msg {
    display: none;
}
// 273902
.order-faq {
    .card-header {
        margin-bottom: 15px;
        h4 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px!important;
            color: $primary;
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }

        .heading-subtitle {
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 15px;

            a{
                white-space: nowrap;
            }
        }
    }
    // 15922
    .faq-heading {
        font-size: 18px;
        font-weight: 600;
        &.link-underline {
            a {
            color: $primary !important;
            text-decoration: underline;
            font-size: 16px;
            }
        }
    }
/*38343 start*/
    .faq-question {
        color: $primary;
        font-size: 14px;
        line-height: 1.18;
        margin-top: 20px;
    }
/*38343 end*/
    .faq-answer {
        color: $bright-gray;
        font-size: 14px;
        line-height: 1.5;
        margin-top: 5px;
    }
}

.nav-tabs .nav-link.paypal-tab.active {
    border-bottom: none;
    img.credit-card-option {
        display: none;
    }
}


//Order Confirmation Page
.page {
    &[data-action="Order-Confirm"],
    &[data-action="Testing-OrderConfirmation"] {
        background-color: $white;
    }
}

.order-confirmation {
    h1 {
        font-size: 16px;
        font-weight: 600;
        color: $primary;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
    }
    a {
        display: inline-block;
        cursor: pointer;
        text-align: left;

        &.print, &.order-confirmation-continue-shopping {
            font-size: 16px;
            text-decoration: underline;
            color: $primary !important;
            border: 0;

        }
        // 273932
        &.order-confirmation-continue-shopping  {
            margin: 24px 0px 16px;
            @include media-breakpoint-down(md) {
                width: 100%;
                text-align: center;
            }
        }
        &.print {
            @include media-breakpoint-down(md) {
                padding: 10px 0;
            }
        }
    }

    .print-receipt {
        text-align: right;
    }
    
    .product-line-item .start-date {
        text-align: left;

        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }

    .product-line-item .product-content {
        span {
            white-space: normal;

            &.product-details {
                font-size: 12px;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    font-size: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .receipt {
        .card {
            border-radius: 0;

            @include media-breakpoint-down(md) {
                margin-right: 0 !important;
                border-radius: 5px!important;
            }
        }

        .summary-section-label {
            margin-bottom: 10px;
            text-transform: capitalize;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            line-height: 0.89;
            color: $primary;
            margin-bottom: 15px;
            display:block;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }

        .order-label {
            color: $black;
        }
        .order-label,
        .summary-details.order-number {
            @include media-breakpoint-down(md) {
                font-size: 14px;
            }
        }

        .order-body-details {
            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }
            .order-total-summary {
                &.card {
                    padding: 16px;
                    @include media-breakpoint-down(md) {
                        padding: 10px;
                    }
                    .order-receipt-label {
                        color: $bright-gray;
                    }
                    .order-receipt-label,
                    .sub-total {
                        font-family: $primary-font-semibold;
                    }
                    .leading-lines {
                        font-family: $primary-font-semibold;
                        padding-bottom: 5px;
                        font-size: 14px;
                        &.grand-total {
                            font-size:16px!important;
                            font-family: $primary-font-bold;
                            color: $dark-charcoal;
                            .order-receipt-label {
                                font-family: $primary-font-bold;
                                color: $dark-charcoal;
                            }
                            &.order-fixed-price {
                                border-top: 0.063em solid $pattens-blue;
                                padding-top: 20px;
                            }
                            &.order-fixed-price,
                            &.order-balancedue {
                                font-size: 14px!important;
                                .order-receipt-label {
                                    color: $bright-gray;
                                }
                             }

                        }
                    }
                }
                
            }
            
        }

        .billing-address-summary {
            .address-summary {
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }
            }
        }

        .order-total-summary {
            .leading-lines {
                &:not(.order-discount) {
                    color: $black;
                }
                font-size: 0.938em !important;  //38375

                .start-lines::before { content: ''; }
            }

            p {
                &.text-right, &.order-receipt-label {
                    margin-bottom: 0;
                }
            }
            // 193175
            // 273902
            .info-not-included {
                p.notincluded {
                    border: 1px solid $pattens-blue;
                    border-width: 1px 0px 0px;
                    margin: 20px 0px 0px;
                    padding: 16px 0px 0px;
                    font-size: 13px;
                    color: $bright-gray;
                }
            }
            // 250750
            // 261624
            .strike-through {
                text-decoration: line-through;
                color: $gray;
            }
        }

        .order-product-summary {
            &:not(.card) {
                border: 1px solid rgba(0, 0, 0, 0.125);
                padding: 24px!important;
                border-radius: 5px;
                @include media-breakpoint-down(md) {
                    padding: 10px !important;
                } 
            }
            .title {
                font-size: 16px;
                color: $primary;
                font-family: $primary-font-bold;
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                } 
            }
            .accricon {
                float:right;
                font-size: 14px;
                color: $primary;
                font-family: $primary-font-bold;

            }
            .card {
                border: 0;
                margin: 1.25rem 0;

                .card-body {
                    padding: 0;
                    border-top: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }
            }

            .leading-lines{
                display: none;
            }
        }
        .bill-summary {
            &-card {
                width: calc(50% - 5px);
                @include media-breakpoint-down(md) {
                    padding: 10px;
                }
                &:nth-child(even) {
                    margin-left: 10px;
                }
            }
        }
    }

    .payment-details {
        .paypal-method-name {
            background-image: url('../../images/pay-pal-logo.png');
            background-repeat: no-repeat;
            background-size: contain;
            text-indent: -9999px;
        }
        .paypal-amount {
            display: none;
        }
        /*38375 start*/
        .paypal-subscription-method-name {
	        background-image: url('../../images/paypal-subscription-summary.png');
            background-repeat: no-repeat;
            background-size: 150px 25px;
            text-indent: -9999px;
            margin-bottom: 12px;
            padding-bottom: 5px;
        }
    }
    .paypal_subscription_summary {
	    color: $black;
	    font-family: $primary-font;
        font-size: 0.938em;
        line-height: 1.25;
    }
    /*38375 start*/
    .container {
        @include media-breakpoint-down(md) { 
            padding:0;    
        }
    }
    .billing-summary {
        .summary-details {
            &.billing {
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                } 
            }
            &.payment-info {
                display: flex;
                flex-flow: column-reverse;
                align-items: flex-start;
                .payment-card-number {
                    font-size: 12px;
                    color: $gray;
                    font-family: $primary-font-semibold;
                    margin-top:5px;
                    span {
                        color: $dark-charcoal;
                    }
                }
            }
        }
    }
    .product-line-item {
        .product-content {
            .product-name {
                color: $secondary-black;
                font-size: 16px;
                font-family: $primary-font;
                margin-bottom: 5px;
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
            }
            .product-details {
                font-size: 12px;
                color: $gray;
                font-family: $primary-font-semibold;
                margin-bottom: 8px;   
            }
        }
        .start-date {
            font-size: 12px;
            color: $primary;
        }
        .product-total {
            font-size: 16px;
            color: $primary;
            position:absolute;
            right: 0px;
            top: 0px;
            @include media-breakpoint-down(md){
                font-size: 14px;
                top: initial;
                bottom: 0px
            }
        }
        + .product-line-item::before {
            border-bottom: 0.063em solid $pattens-blue;
            margin: 0px 0px $receipt-spacing;
        }
        &-details {
            position: absolute;
            bottom: 5px;
            .line-item-attributes {
                font-size: 12px!important;
                color: $primary!important;
                font-family: $primary-font-semibold;
            }
        }
    }
}
 // 15922
.checkout-assistance {
    font-size: 14px;
    color: $black;
    font-family: $primary-font;
    line-height: 1.6;
    .fa {
        color:$primary;
        vertical-align: top;
        font-size: 20px;
        padding-right:5px;
    }
    a {
        font-family: $primary-font-semibold;
        color: $primary !important;
        text-decoration: underline;
    }

}

// fpg updates

.fpg-hide-details {
    .product-summary-block {
        margin-bottom: 0px!important;
        .product-line-item {
            &:not(:first-child) {
                display:none;
            }
        }
    }
    .notincluded {
        margin-top: 5px!important;
        padding-top: 20px;
        border-top: 1px solid $pattens-blue;
    }
}
// 208314
.fpg-product-info-deactive {
    margin-top:0px !important;
    .card.payment-summary  {
     border-top-width: 0px!important;
    }
 }
// 193175
// 206558
 .confirm-details-summary {
    margin-top: 16px;
    .currency-charge-summary {
        font-size: 14px;
        padding: 0px 0px 8px;
        margin-bottom:0px!important;
        color: $dark-charcoal;
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
    }
}
.leading-lines {
    .end-lines,
    .start-lines {
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
        span {
            z-index: 0!important;
        }
    }
} 

@media print {
    .header-top-banner.alert.alert-dismissible,
    .c-navbar-header__col,
    footer,
    .btn-feedback,
    .btn-contactus,
    .order-confirmation-continue-shopping,
    .print-receipt {
        display: none;
    }
}