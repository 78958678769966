header ~ #maincontent .container a:not(.btn-primary):not(.btn-outline-primary) {
    color: inherit;
}

.order-fee {
    color: $black;
}

/*38375 start*/
.subscription-fee {
	color: $black;
}
/*38375 end*/
// 261624
.hide-order-fee {
    display: none!important;
}

.documents {
    div {
        span.delete-docs {
            margin-left: 10px;
            color: red;
            font-weight: 300;
            font-family: Arial, sans-serif;
            cursor: pointer;
        }
    }
}

div.skip:active,
div.skip:focus,
div.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
    color: #444;
}

.skip{
    &.mainnav, &.subcat, &.backcat{
        position: fixed;
    }
}

/*39943*/
.applied-promotion-discount,
.order-discount {
	color: $bright-green;
}
/*39943*/



/*37088*/
.table-primary-title {
	background-color: transparent;
    color: $primary-darkest;
    font-family: $primary-font;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

.table-primary-dark {
	border-collapse: collapse;
	margin: 20px 0px 100px;
		// 69356
		thead {
			th {
		        font-family: $primary-font-bold;
				font-weight: bold;
				color: $white;
				background-color: $primary;
		        font-size: 16px;
				border: 0;
				padding: 20px 20px  20px 32px;
			}
	    }

		tbody {
	        th, td {
	            border-color: $secondary-light;
	            border-style: solid;
	            border-width: 0px 0 1px 0;
				padding: 20px 20px 20px 32px;
				color: $black;

				&:first-child {
					border-left-width: 1px;
				}

				&:last-child {
					border-right-width: 1px;
				}

				a.normal {
					font-family: $primary-font-semibold;
					color: $primary !important;
					font-size: 16px;
				}
	        }
		}

    }

/*37088*/

// 55338
// 55126
.modal-backdrop+.modal-backdrop,
.veil+.veil {
	opacity : 0;
  }
// 55338

// 58339
.modal {
    &.rfi-thankyou-modal {
		.modal-dialog {
			.modal-header {
				padding: 14px;
				.close {
					font-family: $primary-font;
					position: absolute;
					padding: 14px;
					font-weight: normal;
					color: $primary;
					opacity: 1;
					right: 32px;
					top: 24px;
					z-index: 1;
					font-size: 26px;

					@include media-breakpoint-down(sm) {
						font-weight: bold;
						top: 16px;
					}
				}
			}
			.modal-body {
				font-size: 16px;
				font-family: $primary-font; 
				line-height: 24px;
				color: $black;

				.header-logo {
					max-height: 44px;
					@include media-breakpoint-down(sm) {
						max-height: 30px;
					}
				}

				.modal-title {
					font-size: 24px;
					font-family: $primary-font-semibold; 
					line-height: 36px;
					color: $black;
					margin: 25px 0 16px 0;
					font-weight: 500;
					
					@include media-breakpoint-down(md) {
						font-size: 18px;
						line-height: 24px;
					}
				}
				.modal-description{
					p {
						margin-bottom:30px;
						font-size: 16px;

						@include media-breakpoint-down(md) {
							font-size: 14px;
							margin-bottom: 16px;
						}
					}
				}
			}
		}
	}
}
// 58339
// 64851
.veil {
    z-index: 103;
}
// 64851
// 98418
.link-decorater {
	a {
		text-decoration: underline;
		cursor: pointer;
	}
}