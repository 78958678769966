$footer-social-border: 1px solid $secondary;
footer {
    background-color: $primary;
    .social-links {
        float: none;
        width: 100%;

        @media (max-width: 768px){
            margin-top: 8px;
            padding-top: 24px;
            padding-bottom: 24px;
            border-bottom: $footer-social-border;
            border-top: $footer-social-border;
        }

        ul {
            margin: 0;
        }

        li {
            line-height: 0;
            margin: 0 21px 0 0;
            height: 31px;
            width: auto;

            &.fac {
                content: "";
                background: url('../svg/facebook.svg') no-repeat;
                display: inline-block;
                position: relative;
            }
            &.you {
                content: "";
                background: url('../svg/youtube.svg') no-repeat;
                display: inline-block;
                position: relative;
            }
            &.lin {
                content: "";
                background: url('../svg/linkedin.svg') no-repeat;
                display: inline-block;
                position: relative;
            }
            &.ins {
                content: "";
                background: url('../svg/instagram.svg') no-repeat;
                display: inline-block;
                position: relative;
            }
            &.twi {
                content: "";
                background: url('../svg/twitter.svg') no-repeat;
                display: inline-block;
                position: relative;
            }
            a {
                color: $black;
                display: inline-block;
                line-height: 31px;
                width: 31px;
                height: 31px;
            }
        }
    }

    .copyright {
        margin: 0;
        font-size: 16px;
        line-height: 26px;
        background: $primary;
    }

    position: relative;
    // 64614
    .back-to-top{
        right: 16px;
        width: 54px;
        height: 54px;
        background: $yellow;
        border-radius: 13px;
        bottom: 80px;
        margin: 0;
        top: auto;
        display: none;
        z-index: 2;

        @include media-breakpoint-down(sm){
            width: 40px;
            height: 40px;
            border-radius: 7px;
            bottom: 18px;
        }

        a{
            color: $primary-darkest !important; // 79715
            text-decoration: none;
            font-family: $primary-font-extra-bold;
            font-size: 12px;
            line-height: 12px;
            img{
                @include media-breakpoint-down(sm){
                    width: 9px;
                }
            }

            img+img{
                margin-top:-3px;
                @include media-breakpoint-down(sm){
                    margin-top:-2px;
                }
            }

            span{
                margin-top: 8px;
                @include media-breakpoint-down(sm){
                    margin-top: 6px;
                }
            }
        }
    }
}
