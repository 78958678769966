@import "~base/login";
@import "variables";

.page {
    background-color: $white;
}

.login-page {
    margin-top: 30px;

    .login-form-nav {
        .tab-pane {
            margin-top: 40px !important;
        }
    }

    .card-body {
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .btn-submit {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
    }
    .info-feedback {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #5a5d66;
    }
    .custom-control-label, .forgot-password a {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
    }

    .custom-control-label {
        color: $bright-gray;
    }

    .forgot-password {
        a {
            color: $primary !important;
            text-transform: capitalize;
            text-decoration: underline;
        }
    }
}

.nav-tabs {
    .nav-item {
        .nav-link {
            height: 100%;
            font-size: 24px;
            font-weight: 600;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: center;
            color: $primary !important;
            padding-bottom: 20px;

            @include media-breakpoint-down(md) {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                line-height: 1;
            }

            &.active {
                border-bottom: 0.188em solid $primary;
            }
        }
    }
}

form {
    .form-group {

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        &.custom-checkbox {
            label {
                font-size: 14px;
                line-height: 1.29;
                color: $bright-gray;

                @include media-breakpoint-down(md) {
                    position: static;
                    padding-left: 25px;                
                }

                a {
                    text-decoration: underline;
                    color: $primary !important;
                }

                &:before {
                    background: $white;
                    border: 1px solid $primary;
                    border-radius: 0;
                    top: 0;

                    @include media-breakpoint-down(md) {
                        left: 0;
                    }
                }

                &:after {
                    top: 0;

                    @include media-breakpoint-down(md) {
                        left: 0;
                    }
                }
            }

            .custom-control-input:checked ~ .custom-control-label::after {
                background-color:$primary;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }
    }

    &.registration {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-group {
            width: 100%;
            max-width: 100%;
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                max-width: 48%;
            }

            select {
                height: 40px;
                border-radius: 0;
            }

            &.custom-checkbox {
                max-width: 100%;
            }

            #form-country-error {
                .modal-dialog {
                    margin-top:50%;

                    @include media-breakpoint-up(md) {
                        margin-top: 15%;
                    }
                }

                .modal-header {
                    border: 0;
                    padding: 48px 20px 0;
                    box-sizing: border-box;
                    position: relative;

                    .close {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background : url(../../images/modal-exit.png) no-repeat;
                        text-indent: -9999px;
                        width: 20px;
                        height: 20px;
                        background-position: left bottom;
                        z-index: 100;
                        opacity: 1;
                    }
                }

                .modal-body {
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    box-sizing: border-box;
                    padding: 0 48px 48px;
                }
            }
        }

        button.btn-primary {
            max-width: 100%;
            width: 100%;

            @include media-breakpoint-up(md) {
                max-width: 360px;
            }
        }
    }
}