/*37073 136809 start*/
.btn-feedback,
.btn-contactus {
    position: fixed;
    top: 42%;
    right: -4px;
	padding: 9px 35px 12px;
	margin:0px;
	border:0px;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	background: $light-gray;
	font-family: $primary-font;
	font-size: 14px;
	color: $secondary-black;
	z-index: 1;
	cursor: pointer;
	
	&.btn-feedback-right,
	&.btn-contactus-right {
		-ms-transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		-moz-transform: rotate(270deg);
		transform: rotate(270deg);
		-ms-transform-origin: right bottom;
		-webkit-transform-origin: right bottom;
		-moz-transform-origin: right bottom;
		transform-origin: right bottom;
		box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
	}
	&:hover {
		right: -2px;
	}
	
	.modal-open & {
		display: none;
	}
   /*38623 start*/
   @include media-breakpoint-down(md) {
	   display: none;
	}
   /*38623 start*/
 }
/*37073 end*/
.btn-contactus {
	background-color: $yellow;
}
/*37075 start*/
.feedbacksurvey,
.contactussurvey {
    border: 0px !important;
    height: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    display: block !important;
}

.feedback-modal,
.contactus-modal {
	.modal-dialog {
		.modal-content {
			border-radius: 0px;
		   .modal-header {
				.modal-title {
					font-family: $primary-font-extra-bold; 
					font-size: 18px;
					color: $secondary-black;
				}
				
				.close {
					font-family: $primary-font-light;
					color: $primary;
					opacity: 1;
				}
			}
		}
		/*38623 start*/
		@include media-breakpoint-down(md) {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                max-width: 100%;

             .modal-content {
                height: auto;
                min-height: 100%;
                border-radius: 0;
                border: 0px;
            }
		}
		/*38623 start*/
	}
}
.feedback-modal {
	.modal-body {
		height: 85vh;
		overflow-y: auto;
	}
}
/*37075 end*/

/*38623 start*/
.feedback-sm,
.contactus-sm{
	.btn-link {
		font-family: $primary-font-semibold;
		font-size: 12px;
		line-height: 18px;
		color: $primary !important;
		text-decoration: underline;
		border: 0px;
		background: transparent;
		letter-spacing: 0.2px;
	}
	
	@include media-breakpoint-up(lg) {
		display: none;
	}
}
/*38623 end*/


.contactus-info {
	.contactus-info-heading {
		background-color: $primary-darkest;
		border-radius: 0px;
		.close {
			color:$white !important;
		}
		.linebar-heading {
			text-align: center;
			width: auto;
			margin: 0;
			font-family: $secondary-font-medium;
			font-size: 18px;
			line-height: 19px;
			color: $white;
			font-weight: normal;
			letter-spacing: 0;
			@include media-breakpoint-up(md) {
				font-size: 36px;
			}
			
			&:after{
				content:'';
				height: 2px;
				width:64px;
				background: $yellow;
				margin-top:10px;
				margin-bottom: 10px;
				margin-right: auto;
				margin-left: auto;
				display: block;
				@include media-breakpoint-up(md) {
					margin-top:16px;
				}
				@include media-breakpoint-down(sm){
					margin-bottom: 16px;
				}
			}
		}
	}
}
.contactus-info-form {
	.form-group {
		&.required .form-control-label::before {
			content: "*";
			color: $danger;
		}
	}
	.capture-former-name {
		&__option {
			.form-check-input.is-invalid {
				+ label.form-check-label {
					padding-bottom: 16px;
				}
			}
			.form-check-input:checked{
				~ label.form-check-label {
					color: #434343;
					padding-bottom: 0;
				}
				~ .invalid-feedback {
					display: none;
				}
			}
			label.form-check-label {
				font-family: $primary-font;
				font-weight: normal;
				font-size: 14px;
				color: $gray;
				margin: 0;
				padding-left: 24px;
				position: relative;
				line-height: normal;
				cursor: pointer;

				+ .invalid-feedback {
					position: absolute;
					left:12px;
					top: 25px;
				}
				&:before {
					display: block;
					width: 16px;
					height: 16px;
					border: 2px solid $primary;
					content: "";
					position: absolute;
					left: 0;
					top: 1px;
					background: transparent;
				}

				@media (max-width: 768px) {
					font-size: 12px;
				}
			}
			input {
				width: 0;
				height: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				&:focus {
					& + label.form-check-label {
						&:before {
							outline:none;
							box-shadow: none !important;
							&:focus-visible {
								outline: 2px solid #434343 !important;
							}
						}
					}
				}

				&[type="radio"] {
					& + label.form-check-label {
						&:before {
							border-radius: 10px;
						}
						&:after {
							display: block;
							width: 8px;
							height: 8px;
							content: "";
							position: absolute;
							left: 4px;
							top: 5px;
							background: transparent;
							border-radius: 8px;
						}
					}

					&:checked + label.form-check-label {
						&:after {
							background: $primary;
						}
					}
				}
			}
		}
	}
	.form-attachment {
		min-height: 44px;
	}
	.file-input-label {
		@include media-breakpoint-up(sm) {
			width: 350px;
		}
	}
		.input-file-group {
			label.wrapper {
				.form-control span {
					&:first-child {
						width: 100%;
						min-height: 2em;
						display: inline-block;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						vertical-align: top;
						padding: 15px;
						font-size: 16px;
						font-weight: 300;
						line-height: 1;
						letter-spacing: normal;
						color: $black;
						text-align: left;
					}

					&:last-child {
						font-size: 12px;
						font-weight: 300;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: normal;
						color: $bright-gray;
						text-align: right;
					}
				}

				.add-file {
					height:38px;
					width:150px;
					margin-left: 16px;
					font-size: 14px;
					line-height: 1;
					color: $primary !important;
					text-decoration: underline;
					cursor: pointer;                    
				}
			}
			label {
				margin-bottom: 0px;
			}
			.delete-file {
				height: 38px;
				width:150px;
				margin-left: 16px;
				font-size: 14px;
				line-height: 1;
				color: $primary !important;
				text-decoration: underline;
				cursor: pointer;    
				border: 0;
				background: none;                
			}
		}

		.add-new-file {
			.btn {
				text-align: left;
				margin-top: 30px;
				display: flex;
				align-items: center;
				flex-direction: row;
				font-size: 16px;
				line-height: 1;
				color: $bright-gray;
				padding: 0;
				cursor: pointer;

				.fa-plus {
					background-color: $bright-gray;
					border-radius: 25px;
					border: 0;
					color: $white !important;
					margin: 0;
					height: 20px;
					font-size: 12px;
					padding: 0;
					width: 20px;
					padding: 5px 6px;
					margin-right: 8px;
					box-sizing: border-box;
				}

			}
		}

}
.contactus-modal {
	.modal-body {
		min-height: 540px
	}
	.close-contactUs {
		cursor:pointer;
	}
}