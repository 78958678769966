/* ============================================================================
    Global Header Structure
    ============================================================================ */

$ns: st-global-header;

.#{$ns} {
    background-color: $white;

    &__container {
        max-width: 100%;

        @include media-breakpoint-up(xxl) {
            max-width: 1200px;
        }
    }

    &__logo {
        flex-shrink: 0;
        height: auto;

        &__link {
            display: inline-block;
            vertical-align: top;
        }
        &__img {
            height: 24px;
            max-width: 100%;
            vertical-align: top;
            width: auto;

            @include media-breakpoint-up(md) {
                height: auto;
                width: 197px;
            }
        }
    }

    .mobile-menu {
        height: 20px;
        width: 20px;
        @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
        }
    }
}
