$ns: c-navbar-header;

.#{$ns} {
    margin: 25px 0;
    // 63880
    &__links, &__waldenlink, &__requestinfo {
        a {
            color: $primary;
            font-family: $font-nav;
            font-size: 14px;
            font-weight: 600;
            margin-left: 24px;
            line-height: 20px;
            vertical-align: middle;
            letter-spacing: 0.32px;

            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }

            .user-message{
                padding: 0;
                color: $primary;
            }
        }
    }
}
