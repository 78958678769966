$ns : c-review-card;

$card-font: $secondary-font-medium;
$subject-font: $primary-font-italic;

.#{$ns} {
    padding: 24px 0 64px 0;
    background: $primary;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(sm){
        background: $primary; /* Old browsers */
        background: -moz-linear-gradient(top,  $primary 0%, $primary 50%, $white 50%, $white 51%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  $primary 0%,$primary 50%,$white 50%,$white 51%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  $primary 0%,$primary 50%,$white 50%,$white 51%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary', endColorstr='$white',GradientType=0 ); /* IE6-9 */
    }

    
    @include media-breakpoint-up(md) {
        padding: 64px 0 0 0;
    }
    @media (min-width: 769px) and (max-width:992px){
        padding-bottom: 64px;
    }

    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 123px;
        background: $white;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;

        @include media-breakpoint-down(sm){
            display: none;
        }
    }

    .c-module--heading{
        text-align: center;
        width: auto;
        margin: 0;
        font-size: 18px;
        line-height: 19px;
        color: $white;
        font-weight: normal;
        letter-spacing: 0;
        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 36px;
            line-height: 46px;
        }


        &:before{
            display: none;
        }

        &:after{
            content:'';
            height: 2px;
            width:64px;
            background: $yellow;
            margin-top:10px;
            margin-bottom: 40px;
            margin-right: auto;
            margin-left: auto;
            display: block;
            @include media-breakpoint-up(md) {
                margin-top:16px;
                margin-bottom: 64px;
                text-align: left;
                font-size: 36px;
                line-height: 36px;
                margin-left: 0;
            }
        }
    }

    

    &__slide {
        border-radius: 0;
        box-shadow: none;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 50px;
        text-align: center;
        

        @include media-breakpoint-up(md) {
            padding: 43px 58px;
        }
    }

    &__divider {
        width: 32px;
        border: 1px solid $yellow;
        margin: 0 auto 10px auto;

        @include media-breakpoint-up(md) {
            margin: 0 auto 16px auto;
        }
    }

    &__quote {
        font-family: $card-font;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        margin:0 0 30px 0;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            line-height: 20px;            
        }

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 36px;
            margin:0 0 64px 0;
        }

        &:before{
            content:'';
            width: 16px;
            height: 13px;
            background : url(../../images/quote.svg) no-repeat;
            background-size: contain;
            display: block;
            margin: 0 auto 30px auto;            
    
            @include media-breakpoint-up(md) {
                margin: 0 auto 60px auto;
                width: 28px;
                height: 21px;
            }
        }
    }
    // 58167 
    // 69356
    &__name {
        font-family: $primary-font-bold;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        text-align: center;
        color: $white;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            font-size: 14px;
            margin-bottom: 16px;
        }
    }

    &__subject {
        font-family: $subject-font;
        font-style: italic;
        font-size: 12px;
        line-height: 14px;
        color: $white;

        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 15px;
        }
    }
}
// 69356
// 58167
