.hero-banner {
    width: 100vw;
    max-width: calc(100vw);
    margin-left: calc((100vw - 100%) / -2);
    padding: 0;
    position: relative;

    h1 {
	   &:not(.featured_banner_heading) {
	        font-size: 24px;
	        font-weight: bold;
	        line-height: 1.4;
	        font-family: $secondary-font-black;
	        margin-bottom: 10px;
	        letter-spacing: normal;
	        text-align: left;
	
	        @include media-breakpoint-up(md) {
	            font-size: 45px;
	            line-height: 72px;
	        }
		}
    }

    img {
        height: 100%;
        width: 100%;
        display: block;
    }

    p {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.36;
        font-family: $primary-font-semibold;
        letter-spacing: normal;
        text-align: left;

        @include media-breakpoint-up(md) {
            font-size: 20px; 
            line-height: 34px;
            margin-bottom: 24px;
        }

        @media (max-width: 1200px){
            max-width: inherit;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
        }
        @media (max-width: 1100px){                
            font-size: 15px;
            line-height: 24px;
        }
        @media (max-width: 767px){
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
        }
    }

    .hero-bg-image {
        height: 100%;
        width: 100%;
    }

    .banner-content {
        transform: translate(0, 0);
        padding-right: 0;
        max-width: 60%;
        box-sizing: border-box;
        @media (max-width: 1200px){            
            padding: 0 15px;
            max-width: inherit;
        }
    }
    
    .hero-carousel-item {
        position: relative;
        height: 100%;

        .c-module {
            &__featured_banner__text {
                transform: translate(0%, 0%);
                width: 100%;
                left: 0;
                max-width: inherit;
                padding: 7% 10%;
                top: 0;
                bottom: 0;
                margin: auto;
                background: rgba(0,0,0,0.36);

                @media (max-width: 1200px){
                    max-width: inherit;
                    text-align: center;
                }
            }

            &__featured_banner__img {
                height: 100%;

                img {
                    object-fit: cover;
                }
            }
        }
    }

    .banner-track {
        display: flex !important;
        align-items: stretch;
    }
    
    .banner-slide {
        height: inherit !important;
        flex: 1;
    }
// 69359
    .banner-container {
        height: 500px;
        @include media-breakpoint-down(lg) {
            height: 374px;
        }
        @include media-breakpoint-down(md) {
            height: 285px;
        }
        @include media-breakpoint-down(xs) {
            height: 235px;
        }
    }
}