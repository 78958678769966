$ns: site-search;

.#{$ns} {
    width: auto;
    height: auto;
    background-color: $white-smoke;
    margin-right: 0;
    padding: 12px 15px;

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        padding: 0;
    }

    input {
        padding-right: 30px;
    }

    .reset-button {
        top: 0;

        // hack for now to not copy search js for one style
        @include media-breakpoint-down(lg) {
            &.d-sm-block {
                display: none !important;
            };
        }
    }

    .suggestions {

        right: auto;
        left:0;

        @include media-breakpoint-down(md) {
            display: flex;
            position: fixed;
            width: 100%;
        }
        @include media-breakpoint-only(md) {
            height: fit-content;
        }
        .item .name {
            white-space: inherit;
        }
    }
    .suggestions-wrapper {
        #search-results {
            font-size: 14px;
            li {
                max-width: 100%;
                margin: 0;
                padding: 10px;
            }

            .header {
                font-size: 16px;
            }
        }
    }
}
