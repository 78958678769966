h1, h2, h3, h4, h5, h6 {
    font-family: $primary-font;
    // Adding letter spacing to increase readability and closer match the visuals of the comps
    letter-spacing: $letter-spacing;
}

body {
    font-family: $primary-font;
    letter-spacing: $letter-spacing;
    background-color: $white;
    overflow-x: hidden;
}

.container {
    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, xl);
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-up(md) {
        max-width: 100%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1200px;
    }
}

.col-6.col-sm-4 {
    max-width: 100%;
    flex: 100%;
    margin: 1em 0;
}

.col-sm-12.col-md-9 {
    @include media-breakpoint-up(md) {
        flex: 0 0 75% !important;
        max-width: 80% !important;
        padding-right: 0;
        padding-left: 0;
    }
    @include media-breakpoint-up(xl) {
        flex: 0 0 85% !important;
        max-width: 85% !important;
        padding-right: 0;
        padding-left: 25px;
    }
}

.result-count.text-center.col-12.col-md-9.col-sm-6.order-sm-2 {
    @extend .col-sm-12.col-md-9;
}

.refinement-bar.col-md-3 {
    @include media-breakpoint-up(md) {
        padding-right: 25px;
        padding-left: 0;
    }
}

.col-6.col-sm-3.order-sm-1 {
    @include media-breakpoint-up(sm) {
        max-width: 180px;
        padding-right: 25px;
        padding-left: 0;
    }
}

.btn {
    border-radius: 0;
    font-size: 16px;
}

.custom-select {
    border-radius: 0;
    font-family: $primary-font;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;


    background-color: white;
    border: 1px solid $nobel;
    font-size: 16px;
    font-weight: 300;
    height: 42px;

    background-image: url(../svg/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 6px;
    padding: 0 33px 0 13px;
}

a {
    font-family: $primary-font;
    letter-spacing: $letter-spacing;
}

.form-control {
    border-radius: 0;
    background-clip: unset;
}

input {
    font-family: $primary-font;
    letter-spacing: $letter-spacing;
}
// 69356
button {
    font-family: $primary-font-bold;
    font-weight: bold;
    letter-spacing: $letter-spacing;
}
/*63900 63880*/
body{
    .btn, .btn-primay, .btn-secondary{
        font-family: $primary-font-bold;
        font-weight: bold;
        letter-spacing: $letter-spacing;
        border-radius: 50px;
        font-size: 14px;
        line-height: 14px;
        padding: 11px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
/*63900*/
select {
    font-family: $primary-font;
    letter-spacing: $letter-spacing;
}

// PLP select sort background icon
.col-6.col-sm-3.order-sm-1:after {
    font-family: FontAwesome;
    content: '\f078';
    font-size: 12px;
    color: $black;
    display: inline-block;
    position: absolute;
    pointer-events: none;
    right: 15px;
    top: 11px;
    @include media-breakpoint-up(md) {
        right: 35px;
    }
}

// PLP category accordian
.collapsible-xl {
    button {
        padding-left: 0;
        margin-left: 3px;
    }
    .title {
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        text-transform: uppercase;
        &::after {
            content: "\f067";
            font-size: 12px;
            position: absolute;
            top: 17px;
            right: 0;
        }
    }
    &.active {
        .title::after {
            content: "\f068";
        }
    }
}

input[type="date"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

// Paypal button override for margin cut off during redraw
.tab-content {
    > .active.paypal-content {
        .zoid-outlet {
            max-width: 320px !important;
            @include media-breakpoint-up(sm) {
                max-width: 250px !important;
            }
            @include media-breakpoint-up(md) {
                max-width: 340px !important;
            }
            @include media-breakpoint-up(lg) {
                max-width: 450px !important;
            }
            @include media-breakpoint-up(xl) {
                max-width: 640px !important;
            }
        }
    }
}
// 69359
.card {
    margin-bottom: 1em;
}

.card-header h4 {
    margin-bottom: 0;
}

.modal .modal-body {
    flex: 0 0 auto;
}
