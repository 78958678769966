@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";

.minicart {
    position: relative;

    h1 {
        font-size: 1rem;
    }

    .cart {
        padding-top: 0.625em;
        padding-bottom: 0.625em;
        background-color: $body-bg;
    }

    .remove-btn {
        color: $slightly-darker-gray;
        float: right;
        background-color: white;
        border: none;
        font-size: 1.625em;
        margin-top: -0.313em;
        padding: 0;
    }

    .product-summary {
        margin-right: -0.938em;
        max-height: 21.875em;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.938em;
    }

    .card-body {
        padding: 0.625em;
    }

    .quantity-label {
        font-size: 0.813em;
    }

    .quantity {
        width: 100%;
    }

    .popover {
        top: 100%;
        left: auto;
        right: 0;
        min-width: 23.44rem;
        max-width: 23.44rem;
        min-height: 22.7rem;
        display: none;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            display: block;
        }
    }

    .minicart-footer {
        border-top: 1px solid $grey3;
    }

    .estimated-total {
        margin-top: 0.625em;
    }

    .sub-total-label {
        font-size: 1em;
        font-weight: 600;
    }

    .sub-total {
        font-size: 1em;
        font-weight: 600;
    }

    .line-item-divider {
        margin: 0.625em -0.625em 0.625em -1.325em;
    }

    .line-item-name {
        width: 90%;
    }
}

.hide-link-med {

    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {

    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}
