// 61642, 223618, 261624
@import '../variables';

.price {
    .strike-through {
        color: $gray;
        .price {
            font-size: 16px;
        }
    }
    .sales {
        font-weight: normal;
        font-size: 16px;
        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 18px;
        }
    }

    span {
        del {
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-up(md) {
                justify-content: flex-start;
            }
        }

        .strike-through {
            margin: 0;
            font-size: 16px;
            @include media-breakpoint-up(md) {
                font-size: 20px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 20px;
            }
            .price,
            .sales {
                font-size: 20px;
            }
        }
    }
    .range {
        display: flex;
        align-items: center;
    }
}

.s-pdp__attributes {
    .price {
        .sales {
            font-size: 16px;
            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 16px;
            }
        }
    }
}

.cart-minicart {
    .price {
        span {
            align-items: flex-end;
        }
    }
}