@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.add-to-wishlist-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 15%;
    left: 50%;
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}
// 1373
// 81221
// 95030
.wishlistTile {
    position: absolute;
    bottom: 5px;
    right: 12px;
    background-color: rgba(255, 255, 255, .9);
    width: 32px;
    height: 32px;
    border-radius: 50px;
    padding-top: 2px;
    cursor: pointer;
    outline: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    @media (max-width:767px) {
        width: 30px;
        height: 30px;
        right: 6px;
        bottom: 4px;
    }
    .wishlist-icon {
        width: 24px;
        height: 24px;
        fill: #00647C;
        stroke: #00647C;
        cursor: pointer;
        &.active {
            display: none;
        }
    }
    &.wishlist-product-isadded.removeFromWishlist {
        .wishlist-icon {
            display: none;
            &.active {
                display: inline-block;
                animation: beating .5s;
                fill: #ff1493;
                stroke: #ff0000;
            }
       } 
    }
}
// 95030