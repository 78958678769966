.grid-header {
    margin-top: .5em;
    margin-bottom: .5em;
}

.grid-footer-disclosure {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    @include media-breakpoint-up(xl) {
        padding-bottom: 70px;
    }
    div:first-of-type{
        max-width: 730px;
    }
    h4 {
        color: $primary;
    }
    p {
        margin-bottom: 0;
    }
    a {
        color: $primary !important;
    }
}