.veil {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: $black;
    }
}

$spinner-size: 80px;

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    text-align: center;
    animation: sk-rotate 2s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $spinner-size / 2 * -1;
    margin-left: $spinner-size / 2 * -1;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $white;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

@keyframes sk-rotate {
    100% { transform: rotate(360deg); }
}

@keyframes sk-bounce {
    0%,
    100% { transform: scale(0); }
    50% { transform: scale(1); }
}
