@charset "UTF-8";

// SFRA
// @import '~base/global';
// 69357
// 69359
@import "~base/variables";
@import "~base/bootstrap-custom-import";
// @import "~base/bootstrapOverrides";
@import "~base/utilities/responsiveUtils";
@import "~font-awesome/scss/font-awesome";

// @import "flag-icon";

@import "~base/components/common";

@import "~base/components/header";
@import "~base/components/footer";

@import "~base/components/hero";
@import "~base/components/headers";
@import "~base/components/notification";
@import "~base/components/pricing";
@import "~base/components/productTiles";

// @import "~base/components/tabs";
// @import "~base/components/breadcrumb";
@import "~base/components/spinner";

@import "~base/miniCart";
@import "~base/components/tooltip";
@import "~base/components/headerSearch";

//Slick
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

//Helpers
@import 'helpers/svg-inline';
@import 'helpers/svg';

@import './variables';

//Bootstrap override
@import 'bootstrapOverrides';

@import './search';
@import './miniCart';
@import './checkout';
@import './productCard';
@import './login';
// @import './marketingPage/marketingPages';

// BASE
// ============================================================================

// Please Note: All partials inside the base directory should be namespaced with base.

/**
 * Base files are more like normalizing partials. They are the only place where it is is ok to
 * style properties based on an HTML element. If a partial falls outside of the base directory,
 * you should not be styling HTML elements, only classes. The only excepion to this case is if
 * it is a scope or a component that deals with content assets or slots.
 */

@import 'base/base.fonts';

// COMPONENTS
// ============================================================================

// Please Note: All partials inside the components directory should be namespaced with components.

/**
 * Components are concrete, implementation-specific pieces of UI. All of the changes you make to
 * its styles should be detectable in the context you're currently looking at. Modifying these
 * styles should be safe and have no side effects.
 *
 * Please namespace all component classes with c-.
 *
 * Do not remove the {{COMPONENTS}} Sass comment below as it is needed to scaffold
 * out components.
 */

@import "components/common";
@import "components/feedback";
@import 'components/buttons';
@import 'components/grid';
@import "components/pricing";
@import 'components/productTiles';
@import 'components/components.navbar-header';
@import "components/footer";
@import "components/header";
@import "components/headerSearch";
@import "components/hero";
@import "components/menu";
@import "components/navbar";
@import "components/c-search-header";
@import "components/c-module";
@import "components/c-cards";
@import "components/c-review-card";
@import "components/c-homepage";
@import "components/hero-banner";

// Structures
// ============================================================================

// Please Note: All partials inside the components directory should be namespaced with structures.

/**
 * Like components, structures are concrete, implementation-specific pieces of UI. All of the changes you make to
 * its styles should be detectable in the context you're currently looking at. Modifying these
 * styles should be safe and have no side effects. The difference between structures and components
 * is that structures are made up of multiple components.
 *
 * Please namespace all component classes with st-.
 */

@import 'structures/structures.global-header';
@import 'structures/structures.global-footer';

// SCOPES
// ============================================================================

// Please Note: All partial filenames inside this directory should be namespaced with scopes.

/**
 * Scopes create a new styling context. These should be used sparingly. they can be open to abuse
 * and lead to poor CSS if not used wisely. The primary use case for scopes within an SFCC standup
 * is to provide styles within a content asset or content slot. The secondary use case is when
 * styling an SG component in order to easily style the SG classes that are in place.
 *
 * Please namespace all scope classes with s-
 */

@import 'scopes/scopes.pdp';
@import 'scopes/scopes.footer';
@import 'scopes/scopes.request-info';

// Slick override, requires button component
@import 'slickOverrides';
// @import "~plugin_wishlists/components/wishlistGlobal";
@import "components/wishlistGlobal";
