@import "~base/search";

@import "./variables";

$inner_banner__heading-font: $secondary-font-medium;

.page {
    background-color: $white;
}
    

.search-results {
    margin-top: 40px;    // 22196, 76173 Added margin-top 
    .result-count {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    // 27093 Start changes - Enable Hero Banner/additional text box
	// 50636
    .additional-text{
 		h1, .primary-head-text, h3, h2 {
			font-family: $primary-font;
    		color: #003B4C;
			letter-spacing: 0;
			font-size: 28px;
			
			@include media-breakpoint-down(lg) {
				font-size: 22px;	
			}
			
			@include media-breakpoint-down(sm) {
				font-size: 18px;
			}
			
   		}
  		p {
    		color: #000000 !important;
   		}
    }
	// 50636
	span.header-underlined {
    	display: block;
    	position: relative;
    	padding: 0 0 15px;
		&:after {
    		content: '';
    		position: absolute;
   			width: 50px;
    		height: 2px;
    		background: #fbb84b;
    		bottom: 0;
    		left: 50%;
    		margin-left: -25px;
		}
	}
	// 27093 End changes - Enable Hero Banner/additional text box
    .col-sm-3 {
        @include media-breakpoint-down(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            &.order-sm-1 {
                max-width: 100%;
                padding-right: 7.5px;
                padding-left: 15px;
            }
            &.order-sm-3 {
                padding-left: 7.5px;
            }
        }
    }
    .product-grid {
        .col-6 {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    // select has to be a min of 16px for iOS or there will be a zoom / focus event for mobile devices
    select.custom-select {
        font-size: 16px;
        color: $black;
        height: 40px;
        border: 1px solid $nobel;
        border-radius: 0;
        background: none;
        -webkit-appearance: none;
        cursor: pointer;
    }
    button.filter-results {
        color: $black !important;
        text-transform: initial;
        text-align: left;
        border: 1px solid $nobel;
        background: none;
        height: 40px;
        &::after {
            font-family: FontAwesome;
            content: "\f067";
            font-size: 12px;
            position: absolute;
            top: 11px;
            right: 15px;
        }
        &:hover {
            color: $black !important;
            text-transform: initial;
        }
    }
	//30802 start
    .search-nav { // 76173
        margin-left:0px;
        margin-right: 0px;
        border: 0px; // 78048
        @include media-breakpoint-down(sm) {
            margin: 25px 0px;
        }
        // 78048 Start
        .tile-count{
        h3{
            font-family: $primary-font-bold;
            font-size: 14px;
            line-height: 26px;
            color: $gray;
            @media (max-width: 768px){
                font-size: 12px;
                line-height: 18px;
            }
        }
        }
        // 78048 End
        .search-heading {
            font-family: $primary-font-bold;
            font-size:22px;
            line-height: normal;
            color: $secondary-black;
            margin-bottom: 24px;
            @include media-breakpoint-down(lg) {
                font-size: 20px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

        }
       	.nav-tabs{
			.nav-item{
 				.nav-link{
                    background-color: $white; 
                    font-size:22px;
                    @include media-breakpoint-down(sm) {
                        font-size:14px;
                        outline:none!important;
                        padding:15px;
					}
				}
			}
        }
        // 22196 Start
        .col-12 {
        	padding-left:0;
        	padding-right:0;
        }
        // 22196 End
    }
	@include media-breakpoint-down(sm){
		margin-top:10px;
	}
	//30802 End
    // 76173
    .c-filter {
        .c-filter__wrapper {
            .c-filter__body{
                // 78048 Start
                &-sortby {
                    margin-top:36px;
                    min-width: 230px;
                }
                // 78048 End
            }
        } 
    }
}
// 76175
// 92498
.search-no-results {
    .product-grid-header {
        display: flex;
        flex-flow: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .result-header {
        padding-bottom: 16px;
        border-bottom: 3px solid $primary;
        max-width:930px;
        position: relative;
            @include media-breakpoint-down(md) {
                max-width: 660px;
            }
            &:before,
            &:after {
                content: '';
                width: 80px;
                border-bottom: 3px solid $primary;
                bottom: -3px;
                height:1px;
                position: absolute;
                @include media-breakpoint-down(lg) {
                    width: 30px;
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &:before {
                left: -80px;
                @include media-breakpoint-down(lg) {
                    left: -30px;
                }
            }
            &:after {
                right: -80px;
            @include media-breakpoint-down(lg) {
                right: -30px;
            }
        }
    }
    &__heading {
        font-family: $primary-font-bold;
        color: $secondary-black;
        font-size: 22px;
        text-align: center;
        line-height: 26px;
        padding-bottom: 10px;
        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
        // 112605
        &.page-not-found {
            padding-top: 65px;
            @include media-breakpoint-down(md) {
                padding-top: 30px;
            }
        }
    }
    &__subheading {
        font-family: $primary-font-light;
        font-size: 20px;
        @include media-breakpoint-down(lg) {
            font-size: 18px;
        }
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }
    .search-results-products {
        padding-top: 50px;
        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }
    }
    .storepage > .container {
        @include media-breakpoint-down(md) {
            padding: 0px;
        }
    }
}
// 112605
.page-not-found-button {
    margin-top:30px;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
// 76175
.search-result-header {
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
    }
    .search-result-course {
        padding-left: 20px;
        flex-grow: 3;
        @include media-breakpoint-up(xl) {
            flex-grow: 1;
        }
    }
    .search-result-date {
        flex-grow: 0.5;
        @include media-breakpoint-up(xl) {
            flex-grow: 0;
            min-width: 175px;
        }
    }
    .search-result-cost {
        flex-grow: 2;
        @include media-breakpoint-up(xl) {
            flex-grow: 0;
            min-width: 295px;
        }
    }
}

.refinements {
    h2 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    ul {
        li {
            button {
                color: $eastern-blue;
                text-align: left;
                span {
                    color: $black;
                    font-size: 14px;
                    &.selected {
                        color: $eastern-blue;
                    }
                }
            }
        }
    }
    .card-header h2 {
        text-transform: uppercase;
    }
}

.refinement-bar {
    @include media-breakpoint-up(md) {
        max-width: 100%;
    }

    .refinements {
        div:not(.refinement-category) {
            .card-body {
                ul li {
                    button {
                        display: flex;
                        align-items: baseline;
                        flex-direction: row;

                        span {
                            padding-left: 5px;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }
}

.refinement {
    #refinement-category {
        padding: 0 1rem;
        @include media-breakpoint-up(md) {
            padding: 0 0 0 20px;
        }
        ul.values {
            ul.values {
                padding-left: 15px;

                button {
                    span {
                        display: inline-block;
                        margin: 0;
                        padding-left: 0.8em;
                        text-indent: -0.8em;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    &.refinement-category {
        li {
            margin-left: 0;
        }
    }
    @include media-breakpoint-up(md) {
        &.card {
            border-left: none;
            border-right: none;
            border-bottom: none;
            margin-bottom: 0;
            .card-header {
                border: none;
                padding: 9px 0;
            }
            .card-body {
                padding: 10px 0;
                .content {
                    margin-bottom: 0;
                    padding: 0;
                }
                li {
                    padding: 0;
                }
            }
        }
    }
}

.show-more {
    padding: 30px;
    @include media-breakpoint-up(xl) {
        padding: 40px;
    }
    .btn-outline-primary {
        width: 200px;
        height: 50px;
    }
}

.filter-bar {
    li {
        &.filter-value {
            border: 1px solid $nobel;
            border-radius: 0;
        }
    }
}
.refinement-bar{
    margin-top: 0px;
}
.search-featured-courses{
    margin-bottom: 59px;

    @media (max-width: 768px){
        margin-bottom: 24px;
    }

    .c-module--heading.heading-featured-courses{
        text-align: center;
        width: auto;
        margin: 0;
        font-size: 18px;
        line-height: 19px;
        color: $primary-darkest;
        font-weight: normal;
        letter-spacing: 0;
        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 36px;
            line-height: 46px;
        }


        &:before{
            display: none;
        }

        &:after{
            content:'';
            height: 2px;
            width:64px;
            background: $yellow;
            margin-top:10px;
            margin-bottom: 24px;
            margin-right: auto;
            margin-left: auto;
            display: block;
            @include media-breakpoint-up(md) {
                margin-top:16px;
                margin-bottom: 64px;
                text-align: left;
                font-size: 36px;
                line-height: 36px;
                margin-left: 0;
            }
        }
    }
}

// 22196 Changes - Removed the class .content-grid-header

.grid-footer {
    .grid-footer-disclosure{
        display: none;
    }
}

.product-grid{
    margin-bottom: 73px;
    // 54933
    margin-top: 25px;
    // 54933
    padding-top: 0;
    @media (max-width: 768px){
        margin-bottom: 11px;
        margin-top: 21px;
    }
}
.grid-header__custom{
    // 54933
    margin-top:32px;
    // 54933

    // 56062
    @media (max-width: 768px){
        margin-top:20px;
    }
    // 56062

    .grid-header__wrapper{
        .tile-count{
	          font-family: $primary-font;
              font-size: 12px; // 78048
              line-height: 26px;
              color: $gray;
	          @media (max-width: 768px){
	             font-size: 12px;
	             line-height: 18px;
	           }
	   h3{
              font-family: $primary-font;
              font-size: 12px;
              line-height: 26px;
              color: $gray;
	          @media (max-width: 768px){
                  // 56062
	             font-size: 12px; // 78048
                 // 56062
	             line-height: 18px;
	           }
	      }
            .desktop-count{
                @media (max-width: 768px){
                    display: none;
                }
            }
            .mobile-count{

                // 56062
                margin-bottom: 0;
                // 56062
                @media (min-width: 769px){
                    display: none;
                }
            }
        }
        .tile-view-changer{
            display: none;
            .view-changer__wrapper{
                a{
                    margin-left: 20px;
                    svg{
                        display: block;
                        height: 18px;
                        width: auto;

                        @media (max-width: 768px){
                            height: 18px;
                        }
                    }

                    &.active, &:hover{
                        svg{
                            .a{
                                fill: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
// 45204 Start
// 54933
.c-filter {
    .c-filter__sort{
        label{
            font-size: 14px;
            line-height: 16px;
            font-family: $primary-font;
            color: $gray;

            @media (max-width: 768px){
                font-size: 12px;
            }
        }
        .custom-select{
            &.c-filter-select {
                background-image: url(../../images/arrow-down-blue.svg);
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: right 11px center;
                padding: 11px 30px 11px 16px; // 78048
                font-family: "Muli-Bold";
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                border: 1px solid #5A5D66;
                color: #00687F;
                border-radius: 0;
                width: 180px; /*55968*/
                margin-top: 0;

                @media (max-width: 768px){
                    font-size: 12px;
                    padding-left: 8px;
                }
            }
        }
    }    
}
// 54933
// 45204 End


// 54933 bug fix
// 95030
.search-results{
    .tab-content{
        overflow-x: hidden;
        @include media-breakpoint-down(xs){
            padding: 0 15px;
        }
    }
}
// 54933 bug fix

// 59041 mobile sort
html body.hide-sroller{
    @include media-breakpoint-down(sm){
        overflow: hidden !important;
    }
}
// 64851
.mobile-sort{
    display: none;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.active{
        @include media-breakpoint-down(sm){
            display: block;
        }        
    }

    .mobile-sort__backdrop{
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $secondary-black;
        opacity: 0.8;
    }
    .mobile-sort__wrapper{
        left: 0;
        bottom: 0;
        width: 100%;
        background: $white;
        max-height: 100%;
        overflow: auto;
        .mobile-sort__header{
            top: 0;
            left: 0;
            width: 100%;
            padding: 30px 30px 16px 30px;
            background: $white;

            .btn-sort__close{
                padding: 0;                
                width: 16px;
                height: 16px;                
                border: 0 none;
                background: transparent;
                -webkit-appearance: none;
                right:20px;
                top: 20px;

                span{
                    display: block;
                    font-family: $primary-font-light;
                    font-size: 20px;
                    font-weight: 700;
                    color: $primary;
                    line-height: 14px;
                    text-align: center;
                }
            }
            // 69356
            .mobile-sort__title{
                font-family: $primary-font-bold;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $secondary-black;
                padding-bottom: 12px;
                position: relative;

                &:after{
                    display: block;
                    content:'';
                    position: absolute;
                    bottom:0;
                    left:0;
                    width: 20px;
                    height: 2px;
                    background-color: $yellow;
                }
            }
        }
        .mobile-sort__body{
            padding-bottom: 20px;
            nav{
                .nav-link{
                    padding: 14px 30px;
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $primary !important;
                    border-bottom: 2px solid rgba($midgray, 0.8);
                    outline: none !important;
                    
                    &:last-child{
                        border-bottom: 0 none;
                    }

                    &:focus-visible, &:hover{
                        background-color: rgba($primary-lowlight, 0.5);
                    }

                    &.active{
                        background-image: url(../../images/ic_sortCheck.png);               
                        background-repeat: no-repeat;
                        background-position: right 22px center;
                    }
                    // 75204
                    @media (max-width: 345px) {
                        padding: 12px 30px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }        
    }
    .loop-sort{
        display: block;
        overflow:hidden;
        width: 0;
        height: 0;
    }

    &.active{
        .mobile-sort__backdrop{
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            animation-duration: 0.3s;
        }
        .mobile-sort__wrapper{
            -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
            animation-duration: 0.5s;
        }
    }
}

// Fading effect
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.8;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.8;
    }
}

// Slide In from bottom Effect
@-webkit-keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
// 59041 mobile sort


// 59035 Mobile Filter section starts
html{
    overflow: unset !important;
}
.c-filter{
    .c-filter__wrapper{
        .c-filter__selected__mobile{
            display: none;
            @include media-breakpoint-down(sm){
                display: block;
            }
            .c-filter__selected-list{
                .c-filter__selected-item {
                    margin-right: 10px;
                    margin-top: 10px;
                    .c-filter__selected-button {
                        padding: 8px 32px 8px 10px;
                        font-family: $primary-font-semibold;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: normal;
                        color: $white;
                        background: $primary;
                        white-space: nowrap;
    
                        .c-filter__unselect {
                            -webkit-appearance: none;
                            width: 12px;
                            height: 12px;
                            background-image: url(../../images/icon-close.svg);
                            background-color: $primary;
                            color: $white;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 10px;
                            overflow: hidden;
                            text-indent: -9999999px;
                            display: block;
                            position: absolute;
                            right: 10px;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                            border: 0 none;
                        }
                    }
                }
            }
        }
        // 64851 // 75204
        .c-filter__body{
            @include media-breakpoint-down(sm){
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 105;
                background: $white;
                display: none;
                flex-direction: column;
                overflow: auto;

                &.active{
                    display: flex;
                    -webkit-animation-name: slideInUp;
                    animation-name: slideInUp;
                    animation-duration: 0.5s;
                }
            }
        
            .c-filter__items{
                @include media-breakpoint-down(sm){
                    flex-grow: 1;
                }
                .c-filter__selected{
                    @include media-breakpoint-down(sm){
                        display: none !important;
                    }
                }
                .btn-filter__mobile{
                    display: none;
                    padding: 14px 70px 14px 30px;
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $primary;
                    border-bottom: 2px solid rgba($midgray, 0.8);
                    outline: none !important;                    
                    background-repeat: no-repeat;
                    background-position: right 20px center;
                    position: relative;
                    
                    &[aria-expanded="false"]{
                        background-image: url(../../images/arrow-down-blue.svg);
                    }

                    &[aria-expanded="true"]{
                        background-image: url(../../images/arrow-blue-top.svg);
                    }
                    
                    &:focus-visible, &:hover{
                        background-color: rgba($primary-lowlight, 0.5);
                    }
        
                    @include media-breakpoint-down(sm){
                        display: block;
                    }

                    span{
                        font-family: $primary-font-extra-bold;
                        font-size: 12px;
                        line-height: 18px;
                        height: 18px;
                        border-radius: 10px;
                        color: $primary-darkest;
                        background: $pastel-orange;
                        min-width: 18px;
                        display: inline-block;
                        text-align: center;
                        position: absolute;
                        right: 40px;
                        top: 0;
                        bottom: 0;
                        margin: auto;

                        &[aria-hidden=true]{
                            display: none;
                            visibility: hidden;
                        }
                    }
                }
                .c-filter__content{
                    @include media-breakpoint-down(sm){
                        padding: 5px 30px 0 30px;
                        border-bottom: 2px solid rgba($midgray, 0.8);

                        &:empty{
                            display: none;
                        }

                        .c-filter__options-list{
                            column-count: 1;
                        }
                    }
                }
                .c-filter__list{
                    @include media-breakpoint-down(sm){
                        display: none !important;
                        visibility: hidden;
                    }
                }        
            }
            .mobile-filiters__header{
                display: none;
                top: 0;
                left: 0;
                width: 100%;
                padding: 30px 30px 16px 30px;
                background: $white;
                z-index: 1;
                
                @include media-breakpoint-down(sm){
                    display: block;
                }
                .btn-filter__close{
                    padding: 0;                
                    width: 16px;
                    height: 16px;                
                    border: 0 none;
                    background: transparent;
                    -webkit-appearance: none;
                    right:20px;
                    top: 20px;
                    span{
                        display: block;
                        font-family: $primary-font-light;
                        font-size: 20px;
                        font-weight: 700;
                        color: $primary;
                        line-height: 14px;
                        text-align: center;
                    }
                }
                .mobile-filter__title{
                    font-family: $primary-font-bold;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $secondary-black;
                    padding-bottom: 12px;
                    position: relative;
                    &:after{
                        display: block;
                        content:'';
                        position: absolute;
                        bottom:0;
                        left:0;
                        width: 20px;
                        height: 2px;
                        background-color: $yellow;
                    }
                }
            }
            // 69356
            .c-filter__mobile-action{
                display: none;
                padding: 15px 30px;

                .btn.btn-link{
                    color: $primary;
                }
                @include media-breakpoint-down(sm){
                    display: block;
                }
            }

            .loop-filter{
                display: none;
                overflow:hidden;
                width: 0;
                height: 0;
                @include media-breakpoint-down(sm){
                    display: block;
                }
            }
        }
    }
} 
// 59035 Mobile Filter section ends
