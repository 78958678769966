$ns : c-cards;

$card-font: $secondary-font-medium;
$body-font: $primary-font;

.#{$ns} {
    padding: 45px 0 90px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 70px 0 10px;
    }

    &__detail {
        width: 190px;
        height: 340px;
        box-shadow: 0 3px 26px 0 $lagoon;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 0 15px 15px 0;
        padding: 10px 15px;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 230px;
            height: 280px;
            margin: 0 15px 15px 0;
            padding: 20px 15px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 300px;
            height: 340px;
            margin: 0 20px 20px 0;
            padding: 40px 25px;
            justify-content: center;
        }

        @include media-breakpoint-up(xl) {
            width: 360px;
            height: 360px;
            margin: 0 60px 60px 0;
        }
    }

    h3 {
        font-family: $card-font;
        font-size: 18px;
        margin: 15px 0;
        color: $bright-gray;

        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }
    }

    &__img {
        img {
            width: 50px;

            @include media-breakpoint-up(md) {
                width: 70px;
            }

            @include media-breakpoint-up(lg) {
                width: 107px;
            }

        }
    }

    &__body {
        color: $bright-gray;
        font-family: $body-font;
        font-size: 14px;
        line-height: 16px;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
