// 261624
$mincart-fontsize: 13px !default;

.cart-minicart {
    font-size: $mincart-fontsize;
    font-weight: 300;

    &__header {
       // background-color: $light-gray2;
        padding: 15px 20px;

        // 62414
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        // 62414

		// 48866, 146186
        h2{
			font-family: $primary-font;
            font-size: 17px;
			font-weight: 500;
            text-transform: capitalize;
            margin: 0;
            line-height: inherit;
        }
        .minicart-heading  {
            color: $primary;
            font-family: $primary-font-extra-bold;
            text-transform: capitalize;
            font-size: 18px;
            margin: 0;
            line-height: inherit;
        }
        .number-of-items {
            font-family: $primary-font-bold;
            font-size: 16px;
            color: $gray;
            text-align: right;
        }
		// 48866
        a {
            color: $eastern-blue;
            font-size: 17px;
        }
    }

    &__body {
        padding: 0px 20px;
        .remove-line-item {
            display: none;
        }
        .line-item-name {
            white-space: normal;
            text-overflow: unset;
            font-size: $mincart-fontsize!important;
            font-family: $primary-font-bold;
        }
        .line-item-details {
            font-size: 12px!important;
        }
        .line-item-attributes {
            font-weight: 300;
            font-size: $mincart-fontsize;
            margin-bottom: 15px;
        }
        .product-summary {
            overflow: initial;
            max-height: 12em;
            @media (min-height: 795px) {
                max-height: 24em;
            }

            .mini-product-info {
                padding-bottom: 7px;
                &:not(.first) {
                    padding-top: 15px;
                    border-top: 1px solid $pattens-blue;
                }
            }
        }
    }

    &__footer {
        padding: 0 20px;

        &:before {
            content: "";
            display: block;
            border-bottom: 1px solid $secondary-light;
            margin: 8px 0px 16px;

        }
        .books-and-materials {
            text-align: center;
            font-size: 14px;
            color: $bright-gray;
        }
        // 55926, 126088
        .checkout-btn {
            padding-top: 10px;
            padding-bottom: 10px;
            &.btn-minicart {
                background-color: #FDB71A !important;
                border: 2px solid #FDB71A !important;
                color: $primary-darkest !important;
                padding: 11px 20px;
                &:hover,
                &:focus,
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                    color: $primary-darkest !important;
                    background-color: $ocean-green-pearl !important;
                    border-color: $ocean-green-pearl !important;
                }
            }
        }
        .estimated-total {
            margin-top: 0;
        }
        p {
            margin-bottom: 10px;
        }
        .sub-total {
            font-weight: 300;
            font-family: $primary-font;
        }
        .grand-total-row {
            p {
                font-size: 16px!important;
                font-family: $primary-font-bold;
                color: $primary;
                margin-bottom: 0;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 20px;
                }
            }
            .order-discount-savings {
                display: none;
            }
        }
        .grand-total {
            margin-bottom: 0;
        }
        .total-asterisk {
            margin-left: 5px;
        }
    }
}

.minicart {
    .cart {
        background-color: $white;
        padding: 0;

        // 62414
        max-height: calc(100vh - 250px);
        overflow: auto;

        &::-webkit-scrollbar {
            width: 5px; 
        }
        
        &::-webkit-scrollbar-thumb {
            background: $nobel;
            border-radius: 1px;
        }
        // 62414
    }
    .popover {
        border-radius: 12px;
        box-shadow: 0 10px 30px 0 rgba(119, 119, 119, 0.5);
        min-width: 400px;
        max-width: 400px;
        border: 1px solid $light-gray2;
        // 61227
        z-index: 99;
        // 61227

        // 62414
        padding-top: 55px;
        // 62414
    }
    .minicart-footer {
        border: none;

        @include media-breakpoint-up(md) {
            .estimated-total .cart-totals {
                & > .row {
                    display: flex;
                        flex-direction: row;

                    &.hide-order-discount {
                        display: none!important;
                    }
                }

                .price {
                    text-align: right;
                }
            }
        }
    }

    .mini-cart-remove-button {
        .lineitem-remove {
            button {
                background: none;
                color: $primary;
                cursor: pointer;
                font-size: 14px;
                text-decoration: underline;
                position: initial;
                margin-top: 0;
                padding: 0;
                border: 0;
            }
        }
    }
    // 273932
    .cart-discount-tooltip {
        position: relative;
        .tooltip-desktop {
            display: inline-block!important;
        }
        .tooltip-mobile {
            display: none !important;
        }
        &-content {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            right: 15px;
            top: -15px;
            transform: translate(15px, 0);
            background-color: $white;
            padding: 30px 15px 26px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            width: auto;
            min-width: 300px;
            &:before, &:after {
                position: absolute;
                z-index: -1;
                content: "";
                top: 22px;
                right:-8px;
                border-style: solid;
                border-width: 8px 0px 8px 8px;
                border-color: transparent transparent transparent $white;
                transition-duration: 0.3s;
                transition-property: transform;
            }
            &:before {
                border-color: transparent transparent transparent rgba(0, 0, 0, 0.26);
                top: 22px;
                right:-9px; 
            }
        }
        &:hover,
        &:focus,
        &:active {
           .cart-discount-tooltip-content {
                z-index: 10;
                opacity: 1;
                visibility: visible;
                transform: translate(-10px, 0px);
                transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
           }
        }
        &-heading {
            font-size: 12px;
            color: $gray;
            font-family: $primary-font-semibold;
            margin-bottom: 4px;
        }

        &-discount {
            &-name,
            &-value {
                font-size: 14px;
                color:$night-rider;
                font-family: $primary-font-bold;
                &.col-total {
                    font-size: 16px;
                    margin-bottom: 0px;
                }
            }
            &-value {
                color:$bright-green;
                text-align: right;
            }
        }

    }
}

.minicart-total {
    min-width: 50px;
    .account-user__icon {
        @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
        }
    }
}

.total-asterisk {
    vertical-align: super;
}

.cart-totals .strike-through {
    text-decoration: line-through;
    color: $gray;
}

.cart-totals .no-margin {
    margin-bottom: 0px;
}