// 58167
%review-carousel {
    &.slick-dotted.slick-slider {
        margin: 0 auto;
        max-width: 100%;
        width: 300px;     

        @media (min-width:420px) {
            width: 400px;
        } 

        @include media-breakpoint-up(sm) {
            width: 500px;
        }       
        @include media-breakpoint-up(md) {
            width: 703px;
        }
    }
    .slick-list {
        padding: 0px !important;
        margin: 0 0 0 0;
         
    }
    .slick-slide {
        background-color: $primary-darkest;
        width: 300px;
        height: auto;
        margin: 0 15px;

        @media (min-width:420px) {
            width: 400px;
        }

        @include media-breakpoint-up(sm) {
            width: 500px;
        } 
        @include media-breakpoint-up(md) {
            width: 703px;
            margin: 0 30px;
        }

        .c-review-card__slide{
            background: transparent;
            color: $white;
        }
    }
    .slick-dots {
        
        
        width: auto;
        left: auto;            
        white-space: nowrap;
        bottom: 0;
        right: auto;
        @include media-breakpoint-up(md){
            bottom: 162px;
            right: calc(100% + 42px);
        }
        @media (max-width:1100px){
            right: calc(100% + 8px);
        }
        @media (max-width:992px){
            bottom: -32px;
            right: 0;
            left: 0;
            margin: auto;
        }

        li {
            &.slick-active button:before {
                color: $pastel-orange;
            }

            width: 8px;
            height: 8px;

            button {
                @include sliderBtns('', '', 15px);
                width: 8px;
                height: 8px;
                padding: 0;

                &:before{
                    color: $midgray;
                    opacity: 1;
                    font-size: 12px;
                    line-height: 12px;
                    width: 12px;
                    height: 12px;
                }

                &:hover{
                    &:before{
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                @include media-breakpoint-up(md) {
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    @include sliderBtns('', '', 25px);
                    &:before{
                        color: $midgray;
                        opacity: 1;
                        font-size: 12px;
                        line-height: 12px;
                        width: 12px;
                        height: 12px;
                    }

                    &:hover{
                        &:before{
                            font-size: 12px;
                            line-height: 8px;
                        }
                    }
                }
            }
        }
    }
    .slick-prev {
        @include sliderBtns(left);
        background: url(../../images/arrow-white.svg) no-repeat;
        background-size: cover;
        transform-origin: center 7px;
        transform: rotate(180deg);
        height: 25px;
        width: 14px;
        left: -45px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &:before{
            display: none;
        }
        @include media-breakpoint-up(md) {               
            height: 41px;
            width: 22px;
            transform-origin: center 11px;
        }

        @include media-breakpoint-down(md) {
            left: 11px;
        }
        @include media-breakpoint-up(xl) {
            left: -220px;
            height: 41px;
            width: 22px;
            transform-origin: center 11px;
        }

    }
    .slick-next {
        @include sliderBtns(right);
        background: url(../../images/arrow-white.svg) no-repeat;
        background-size: cover;
        height: 25px;
        width: 14px;
        right: -45px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &:before{
            display: none;
        }

        @include media-breakpoint-up(md) {               
            height: 41px;
            width: 22px;
        }

        @include media-breakpoint-down(md) {
            right: 11px;
            
        }
        @include media-breakpoint-up(xl) {
            right: -220px;
            height: 41px;
            width: 22px;
        }
    }
}
// 76175
.student-review-card__slides,
.c-review-card__slides,
.product-tiles__list,
.product-tiles-recent__list,
.product-search-result__list  {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
}

// homepage review slick overrides
.home-main {
    .how-it-works .container {
        @include media-breakpoint-down(md) {
            padding-bottom: 70px;
            padding-left: 0;
            padding-right: 0;
        }
        .c-cards {
            width: 190px;
            padding-bottom: 25px;
            margin: auto;
            @include media-breakpoint-up(md) {
                width: 100%;
            }
            .c-cards__img img {
                margin: auto;
            }
        }
    }
    .how-it-works .c-cards.slick-initialized {
        &.slick-dotted.slick-slider {
            margin: 0 auto;
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
        .slick-slide {
            width: 190px;
            height: 315px;
            margin: 0 5px;
            @include media-breakpoint-up(md) {
                width: 320px;
            }
            @include media-breakpoint-up(md) {
                width: 600px;
                margin: 0 30px;
            }
        }
        .slick-dots {
            li {
                &.slick-active button:before {
                    color: $pastel-orange;
                }
                button {
                    @include sliderBtns('', '', 15px);
                    
                    @include media-breakpoint-up(md) {
                        @include sliderBtns('', '', 25px);                        
                    }
                }
            }
        }
    }
    .c-review-card__slides.slick-initialized {
        visibility: visible;
        opacity: 1;  
        @extend %review-carousel;
    }

    .hero-carousel.slick-initialized {
        margin-bottom: 0 !important;

        .slick-dots {            
            
            width: 150px;
            height: 8px;
            left: 0;            
            white-space: nowrap;
            bottom: 40px;
            right: 0;
            margin: auto;  
            
            @media (max-width: 1100px){
                bottom: 15px;
            }
            

            li {                
                
                &.slick-active button:before {
                    color: $pastel-orange;
                }

                width: 8px;
                height: 8px;
                vertical-align: top;

                button {
                    @include sliderBtns('', '', 15px);
                    width: 8px;
                    height: 8px;
                    padding: 0;

                    &:before{
                        color: $midgray;
                        opacity: 1;
                        font-size: 12px !important;
                        line-height: 12px;
                        width: 12px;
                        height: 12px;
                    }

                    &:hover{
                        &:before{
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        width: 8px;
                        height: 8px;
                        padding: 0;
                        @include sliderBtns('', '', 25px);
                        &:before{
                            color: $midgray;
                            opacity: 1;
                            font-size: 12px;
                            line-height: 12px;
                            width: 12px;
                            height: 12px;
                        }

                        &:hover{
                            &:before{
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }

        .slick-dots {
            li {
                &.slick-active button:before {
                    color: $pastel-orange;
                }

                button {
                    @include sliderBtns('', '', 15px);
                    @include media-breakpoint-up(md) {
                        @include sliderBtns('', '', 25px);
                    }
                }
            }
        }

        .slick-prev {
            @include sliderBtns(left);
            background: url(../../images/arrow-gray.svg) no-repeat;
            background-size: cover;
            transform-origin: center 7px;
            transform: rotate(180deg);
            height: 25px;
            width: 14px;
            left: 31px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            opacity: 0.7;

            &:before{
                display: none;
            }
            &:hover, &:focus{
                background: url(../../images/arrow-yellow.svg) no-repeat;
                background-size: cover;
                opacity: 1;
            }

            @include media-breakpoint-up(md) {               
                height: 41px;
                width: 22px;
                transform-origin: center 11px;
                left: 41px;
            }

            @include media-breakpoint-down(md) {
                
            }
            @include media-breakpoint-up(xl) {                
                height: 41px;
                width: 22px;
                transform-origin: center 11px;
                left: 41px;
            }
        }
        
        .slick-next {
            background: url(../../images/arrow-gray.svg) no-repeat;
            background-size: cover;
            height: 25px;
            width: 14px;
            right: 31px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            opacity: 0.7;

            &:before{
                display: none;
            }

            &:hover, &:focus{
                background: url(../../images/arrow-yellow.svg) no-repeat;
                background-size: cover;
                opacity: 1;
            }

            @include media-breakpoint-up(md) {               
                height: 41px;
                width: 22px;
                right: 41px;
            }

            @include media-breakpoint-down(md) {
                
                
            }
            @include media-breakpoint-up(xl) {                
                height: 41px;
                width: 22px;
                right: 41px;
            }
        }
    }
}
// 26274 start
.slick-loading .slick-list{
    background: none !important;
}
// 26274 end

// 54916
.user-testimonials-pdp {
    .slick-slider {
        .slick-slide{
            position:absolute;
            visibility: hidden;
            &:first-child{
                position: relative;
            }
        }
    }

    .slick-initialized {
        &.slick-slider {
            .slick-slide {
                position: relative;
                &:first-child {
                    position: relative;
                }
                &.slick-current,
                &.slick-active {
                    visibility: visible;
                    width:100%
                }
            }
        }
        &.carousel-initialized {
            &.slick-slider {
                .slick-slide {
                    visibility: visible;
                }
            }
        }
    }
}

// 58167
// 65636
// 105361
.student-reviews {
    &.c-review-card {
        .student-review-card__slides {
            &.slick-initialized {
                visibility: visible;
                opacity: 1;  
                @extend %review-carousel;
            }
        }

        .slick-track {      
            .c-review-card__quote {
                min-height: 190px;
                @include media-breakpoint-down(sm) {
                    min-height: 110px;
                }
            }
        }
    }
}
// 58167
// 65636
// 76175
.product-tiles__list,
.product-tiles-recent__list,
.product-search-result__list {
    &.slick-initialized {
        visibility: visible;
        opacity: 1; 
    }
}