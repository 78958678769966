@import "quickView";

.product-tile {
    @include media-breakpoint-down(md) {
        min-height: auto;
    }
    @include media-breakpoint-down(sm) {
        min-height: auto;
    }
    @include media-breakpoint-down(xs) {
        min-height: auto;
    }
    background-color: $white-smoke;
    .tile-body {
        display: flex;
        padding: 20px 0 0;
        font-size: 16px;
        min-height: 100px;
        flex-wrap: wrap;
        @include media-breakpoint-up(sm) {
            padding: 0;
            flex-wrap: nowrap;
        }
        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 16px;
        }
        .product-tile-name {
            display: flex;
            flex-grow: 4;
            flex-direction: column;
            justify-content: center;
            margin: 0 20px;
            padding: 0 0 20px;
            width: 100%;
            border-bottom: 1px solid $dark-smoke;
            @include media-breakpoint-up(sm) {
                margin: 0;
                padding: 0;
                width: inherit;
                max-width: 260px;
                border-bottom: none;
            }
            @include media-breakpoint-up(xl) {
                flex-grow: 1;
                max-width: inherit;
            }
            div {
                text-align: left;
                padding: 0;
                color: $bright-gray;
                font-size: 14px;
                @include media-breakpoint-up(sm) {
                    padding: 0 20px;
                }
                @include media-breakpoint-up(md) {
                    font-size: 12px;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 14px;
                }
            }
            div:first-of-type {
                font-weight: 600;
                color: $black;
                font-size: 18px;
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 16px;
                }
            }
        }
        .product-tile-date {
            color: $primary;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            @include media-breakpoint-up(sm) {
                flex-grow: 2;
            }
            @include media-breakpoint-up(xl) {
                min-width: 180px;
                flex-grow: 0;
            }
            div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                padding: 20px;
                height: 80%;
                @include media-breakpoint-up(sm) {
                    text-align: center;
                    padding: 0;
                    border: 1px solid $dark-smoke; 
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
        .price {
            color: $primary;
            padding: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: right;
            flex-grow: 1;
            @include media-breakpoint-up(sm) {
                padding: 0 5px;
                text-align: center;
            }
            @include media-breakpoint-up(xl) {
                min-width: 104px;
                flex-grow: 0;
            }
        }
        .product-tile-button {
            flex-grow: 2;
            width: 100%;
            height: 50px;
            @include media-breakpoint-up(sm) {
                width: inherit;
                height: auto;
            }
            @include media-breakpoint-up(xl) {
                min-width: 200px;
                flex-grow: 0;
            }
            form {
                height: 100%;
            }
            .product-tile-btn {
                height: 100%;
                width: 100%;
                padding: 0 35px 0 0;
                @include button;
                @include background-svg($svg-arrow-right);
                background-repeat: no-repeat;
                background-position: 68% 48%;
                @include media-breakpoint-up(sm) {
                    background-position: 90% 48%;
                }
                @include media-breakpoint-up(md) {
                    background-position: 88% 48%;
                }
                @include media-breakpoint-up(xl) {
                    background-position: 85% 48%;
                }
                 &:hover{
                    @include background-svg($svg-arrow-right-primary);
                }
            }
        }
    }
}
