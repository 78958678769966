$ns: s-pdp;

$product_name-font: $primary-font;
$product_detail__title-font: $primary-font;
$product_detail__title-color: $black;
$product_detail__value-font: $primary-font; /* Story#16082 Changed from $primary-font-light to $primary-font */
$product_detail__value-color: $bright-gray;
$product_detail__border: 1px solid $dark-smoke;
$product_attr_price-font: $primary-font;

.#{$ns} {
    &__name {
        font-family: $product_name-font;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 30px;
            max-width: 800px;
        }

        &.product-name {
            border: none;
        }
    }

    &__main-content {
        @include media-breakpoint-up(sm) {
            background-color: $white-smoke;
            padding: 20px;
        }
    }

    &__attributes {
        .lre_start_date {
            display: none;
        }

        .sales {
            color: $primary;
            font-family: $product_attr_price-font;
            font-weight: 600;
            font-size: 18px;
        }
    }

    &__other-attr {
        margin-bottom: 30px;

        span {
            color: $primary;
            font-size: 16px;
            padding: 0 10px;
            border-right: 2px solid $primary;

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }
        }
    }

    &__detail {
        border-bottom: $product_detail__border;

        &:first-child {
            margin-top: 0;
            border-top: $product_detail__border;

            @include media-breakpoint-up(sm) {
                border-top: none;
            }
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: $product_detail__border;

            @include media-breakpoint-up(sm) {
                border-bottom: none;
            }
        }

        @include media-breakpoint-up(sm) {
            margin: 20px 0;
            padding-bottom: 20px;
        }

        &__title {
            font-family: $product_detail__title-font;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: $product_detail__title-color;
            margin-bottom: 10px;
            width: 100%;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }

        &__value {
            font-family: $product_detail__value-font;
            font-size: 14px;
            line-height: 18px;
            color: $product_detail__value-color;
            width: 100%;
            padding-bottom: 15px;

            @include media-breakpoint-up(sm) {
                padding: 0;
            }
        }

        &__btn-title {
            padding: 0;
            font-size: 14px;
            font-family: $primary-font;
                &:after {

                }
        }
    }
}

/* PDP overrides */
.container {
    &.product-detail {
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 70px;
        }

        .breadcrumb {
            .breadcrumb-item {
                a {
                    font-family: $primary-font;
                    color: $black !important;
                }
            }
        }
    }
}

.product-options {
    display: none;
}

.product-availability {
    display: none;
}

.add-to-cart {
    i {
        display: none;
    }
}

.prices {
    padding: 0;
}

.attribute {
    margin-top: 0;
}

.prices-add-to-cart-actions {
    padding: 0;
    margin: 15px auto 30px;
    position: relative;
    box-shadow: none;
    background: none;
}

.cart-and-ipay {
    padding: 0;

    .btn {
        width: 100%;
        margin: 0;
    }
}

.collapsible-xs {
    &.active {
        .title {
            &:after {
                @include media-breakpoint-down(xs) {
                    content: "";
                    background: url(../svg/minus.svg) no-repeat;
                    display: block;
                    height: 10px;
                    width: 14px;
                    margin-top: 19px;
                }
            }
        }
    }

    .title {
        &:after {
            @include media-breakpoint-down(xs) {
                content: "";
                background: url(../svg/plus.svg) no-repeat;
                display: block;
                height: 10px;
                width: 14px;
                margin-top: 15px;
            }
        }
    }
}

.admissions_requirements {
    &__section-heading,
    &__transcript-details {
        font-family: $primary-font;
        font-weight: bold;
        color: $black;
    }
    &__section {
        line-height: 20px;

        .roman {
            list-style-position: inside;
            padding: 10px 0 10px 15px;
        }
    }
    &__container {
        .number {
            counter-reset: item;
            padding-left: 0;

            & > li {
                list-style-type: none;
                counter-increment: item;

                &:before {
                    color: $black;
                    font-family: $primary-font;
                    font-weight: bold;
                    text-align: right;
                    content: counter(item) ".";
                    display: inline-block;
                }
            }
        }
    }
}
