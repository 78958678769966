/* ============================================================================
   Fonts
   ============================================================================ */

/* @import must be at top of file, otherwise CSS will not work */
// 69357
// 69359
// GillSans Light
/*
@font-face{
    font-family: "Gill Sans Light";
    src: url("../fonts/web/gill-sans-wgl-w01-light.eot?#iefix");
    src: url("../fonts/web/gill-sans-wgl-w01-light.eot?#iefix") format("eot"),
         url("../fonts/web/gill-sans-wgl-w01-light.woff2") format("woff2"),
         url("../fonts/web/gill-sans-wgl-w01-light.woff") format("woff"),
         url("../fonts/web/gill-sans-wgl-w01-light.ttf") format("truetype");
}

// GillSans Regular
@font-face{
    font-family: "Gill Sans Regular";
    src: url("../fonts/web/gill-sans-wgl-w01-regular.eot?#iefix");
    src: url("../fonts/web/gill-sans-wgl-w01-regular.eot?#iefix") format("eot"),
         url("../fonts/web/gill-sans-wgl-w01-regular.woff2") format("woff2"),
         url("../fonts/web/gill-sans-wgl-w01-regular.woff") format("woff"),
         url("../fonts/web/gill-sans-wgl-w01-regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../fonts/web/GillSans.eot');
    src: url('../fonts/web/GillSans.eot?#iefix') format('embedded-opentype'),
        url('../fonts/web/GillSans.woff2') format('woff2'),
        url('../fonts/web/GillSans.woff') format('woff'),
        url('../fonts/web/GillSans.ttf') format('truetype'),
        url('../fonts/web/GillSans.svg#GillSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans Bold';
    src: url('../fonts/web/GillSans-Bold.eot');
    src: url('../fonts/web/GillSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/web/GillSans-Bold.woff2') format('woff2'),
        url('../fonts/web/GillSans-Bold.woff') format('woff'),
        url('../fonts/web/GillSans-Bold.ttf') format('truetype'),
        url('../fonts/web/GillSans-Bold.svg#GillSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Source Serif Pro Regular
@font-face{
    font-family: "Source Serif Pro Regular";
    src: url("../fonts/web/sourceserifpro-regular-webfont.eot?#iefix");
    src: url("../fonts/web/sourceserifpro-regular-webfont.eot?#iefix") format("eot"),
         url("../fonts/web/sourceserifpro-regular-webfont.woff2") format("woff2"),
         url("../fonts/web/sourceserifpro-regular-webfont.woff") format("woff"),
         url("../fonts/web/sourceserifpro-regular-webfont.ttf") format("truetype");
}

// GillSans Semi Bold
@font-face{
    font-family: "Source Serif Pro Semibold Regular";
    src: url("../fonts/web/sourceserifpro-semibold.eot?#iefix");
    src: url("../fonts/web/sourceserifpro-semibold.eot?#iefix") format("eot"),
         url("../fonts/web/sourceserifpro-semibold.woff2") format("woff2"),
         url("../fonts/web/sourceserifpro-semibold.woff") format("woff"),
         url("../fonts/web/sourceserifpro-semibold.ttf") format("truetype");
}

// GillSans Bold
@font-face {
    font-family: "Source Serif Pro Bold";
    src: url("../fonts/web/sourceserifpro-bold-webfont.eot?#iefix");
    src: url("../fonts/web/sourceserifpro-bold-webfont.eot?#iefix") format("eot"),
         url("../fonts/web/sourceserifpro-bold-webfont.woff2") format("woff2"),
         url("../fonts/web/sourceserifpro-bold-webfont.woff") format("woff"),
         url("../fonts/web/sourceserifpro-bold-webfont.ttf") format("truetype");
}
*/
// 69356 
// 63880
// 69358
@font-face {
  font-family: 'Muli-Bold';
  src: url('../fonts/web/Muli-Bold.woff2') format('woff2'), 
  url('../fonts/web/Muli-Bold.woff') format('woff'), 
  url('../fonts/web/Muli-Bold.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli-ExtraBold';
  src: url('../fonts/web/Muli-ExtraBold.woff2') format('woff2'),
  url('../fonts/web/Muli-ExtraBold.woff') format('woff'), 
  url('../fonts/web/Muli-ExtraBold.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli-Italic';
  src:   url('../fonts/web/Muli-Italic.woff2') format('woff2'), 
  url('../fonts/web/Muli-Italic.woff') format('woff'), 
  url('../fonts/web/Muli-Italic.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli-Light';
  src: url('../fonts/web/Muli-Light.woff2') format('woff2'),  
       url('../fonts/web/Muli-Light.woff') format('woff'), 
       url('../fonts/web/Muli-Light.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli-Regular';
  src: url('../fonts/web/Muli-Regular.woff2') format('woff2'), 
  url('../fonts/web/Muli-Regular.woff') format('woff'), 
  url('../fonts/web/Muli-Regular.ttf')  format('truetype');;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: url('../fonts/web/Muli-SemiBold.woff2') format('woff2'),  
  url('../fonts/web/Muli-SemiBold.woff') format('woff'), 
  url('../fonts/web/Muli-SemiBold.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family:"NotoSerifSC-Medium-Alphabetic";
  src: url('../fonts/web/NotoSerifSC-Medium-Alphabetic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/web/NotoSerifSC-Medium-Alphabetic.woff2') format('woff2'),
       url('../fonts/web/NotoSerifSC-Medium-Alphabetic.woff') format('woff'),
       url('../fonts/web/NotoSerifSC-Medium-Alphabetic.svg#NotoSerifSC') format('svg');
  font-style: normal;
  font-weight: 500;
}*/

@font-face {
  font-family:"NotoSerifSC-Black-Alphabetic";
  src: url('../fonts/web/NotoSerifSC-Black-Alphabetic.woff2') format('woff2'),
       url('../fonts/web/NotoSerifSC-Black-Alphabetic.woff') format('woff'),
       url('../fonts/web/NotoSerifSC-Black-Alphabetic.svg#NotoSerifSC') format('svg');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "noto-serif";
  src: url("../fonts/web/NotoSerif-400.woff2") format("woff2"),
       url("../fonts/web/NotoSerif-400.woff") format("woff"),
       url("../fonts/web/NotoSerif-400.otf") format("opentype");
  font-style:normal;
  font-weight:400;
  font-display: swap;
  }
  
@font-face {
  font-family: "noto-serif";
  src: url("../fonts/web/NotoSerif-400.woff2") format("woff2"),
       url("../fonts/web/NotoSerif-400.woff") format("woff"),
       url("../fonts/web/NotoSerif-400.otf") format("opentype");
  font-style:normal;
  font-weight:500;
  font-display: swap;
  }
 // 89350
  @font-face {
    font-family: "noto-serif";
    src: url("../fonts/web/NotoSerief-italic.woff2") format("woff2"),
         url("../fonts/web/NotoSerief-italic.woff") format("woff"),
         url("../fonts/web/NotoSerief-italic.otf") format("opentype");
    font-style: italic;
    font-weight: 600;
    font-display: swap;
  }

/*
// 58165
@font-face {
  font-family: 'NotoSerif-Regular';
  src: url('../fonts/web/NotoSerifSC-Regular.eot');
  src: url('../fonts/web/NotoSerifSC-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/web/NotoSerifSC-Regular.woff2') format('woff2'),
      url('../fonts/web/NotoSerifSC-Regular.woff') format('woff'),
      url('../fonts/web/NotoSerifSC-Regular.ttf') format('truetype'),
      url('../fonts/web/NotoSerifSC-Regular.svg#NotoSerifSC-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerif-Bold';
  src: url('../fonts/web/NotoSerif-Bold.eot');
  src: url('../fonts/web/NotoSerif-Bold-Alphabetic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/web/NotoSerif-Bold.woff2') format('woff2'),
      url('../fonts/web/NotoSerif-Bold.woff') format('woff'),
      url('../fonts/web/NotoSerif-Bold.ttf') format('truetype'),
      url('../fonts/web/NotoSerif-Bold.svg#NotoSerif-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSerifKR-Medium-Alphabetic';
  src: url('../fonts/web/NotoSerifKR-Medium-Alphabetic.eot');
  src: url('../fonts/web/NotoSerifKR-Medium-Alphabetic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/web/NotoSerifKR-Medium-Alphabetic.woff2') format('woff2'),
      url('../fonts/web/NotoSerifKR-Medium-Alphabetic.woff') format('woff'),
      url('../fonts/web/NotoSerifKR-Medium-Alphabetic.ttf') format('truetype'),
      url('../fonts/web/NotoSerifKR-Medium-Alphabetic.svg#NotoSerifKR-Medium-Alphabetic') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/
// 69356